import { ExportRequestActionTypes } from './constants';
import {isEmptyObject} from "../../helpers/functions";

const INIT_STATE = {
    exportRequests: {},
    exportRequest: {},
    queryParams: {},

    loading: false,
    error: "",
    validationErrors: {},

    searchModal: false,
    tmpExportRequests: {},
    tmpQueryParams: {},
    searchMode: false,
    searchLoading: false,
    searchError: "",
    searchValidationErrors: {},

};


type ExportRequestActionType = {
    type:
        | ExportRequestActionTypes.API_RESPONSE_SUCCESS
        | ExportRequestActionTypes.API_RESPONSE_ERROR
        | ExportRequestActionTypes.API_RESPONSE_VALIDATION_ERRORS
        | ExportRequestActionTypes.GET_LOGS
        | ExportRequestActionTypes.GET_LOG
        | ExportRequestActionTypes.RESET

        | ExportRequestActionTypes.OPEN_SEARCH
        | ExportRequestActionTypes.CLOSE_SEARCH
        | ExportRequestActionTypes.SEARCH_LOG
        | ExportRequestActionTypes.SEARCH_TRANSACTION
        | ExportRequestActionTypes.CLEAR_SEARCH;

    payload: {
        actionType?: string;
        data?: any;
        error?: string;
        validationErrors?: [];
    };
};

type State = {
    exportRequests?: {};
    queryParams?: {};


    loading?: boolean;
    value?: boolean;
    tmpExportRequests?: any;
    tmpQueryParams?: {};
};

const ExportRequest = (state: State = INIT_STATE, action: ExportRequestActionType) => {
    switch (action.type) {
        case ExportRequestActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case ExportRequestActionTypes.GET_LOGS: {
                    return {
                        ...state,
                        exportRequests: action.payload.data?.exportRequests,
                        queryParams: action.payload.data?.queryParams,
                        loading: false,
                    };
                }
                case ExportRequestActionTypes.GET_LOG: {
                    return {
                        ...state,
                        exportRequest: action.payload.data,
                        loading: false,
                    };
                }

                case ExportRequestActionTypes.SEARCH_LOG: {
                    return {
                        ...state,
                        // this condition used for prevent multiple search
                        tmpExportRequests: isEmptyObject(state.tmpExportRequests)? state.exportRequests: state.tmpExportRequests,
                        tmpQueryParams: isEmptyObject(state.tmpQueryParams)? state.queryParams: state.tmpQueryParams,

                        exportRequests: action.payload.data?.exportRequests,
                        queryParams: action.payload.data?.queryParams,

                        searchMode: true,
                        searchModal: false,
                        searchLoading: false,
                    };
                }

                default:
                    return { ...state };
            }

        case ExportRequestActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case ExportRequestActionTypes.GET_LOGS:
                case ExportRequestActionTypes.GET_LOG:
                 {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }
                case ExportRequestActionTypes.SEARCH_LOG:
                case ExportRequestActionTypes.SEARCH_TRANSACTION: {
                    return {
                        ...state,
                        searchError: action.payload.error,
                        searchLoading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case ExportRequestActionTypes.API_RESPONSE_VALIDATION_ERRORS:

            switch (action.payload.actionType) {
                case ExportRequestActionTypes.GET_LOGS:
                {
                    return {
                        ...state,
                        error: "Invalid input error",
                        loading: false,
                    };
                }
                case ExportRequestActionTypes.GET_LOG:
                {
                    return {
                        ...state,
                        validationErrors: action.payload.validationErrors,
                        loading: false,
                    };
                }
                case ExportRequestActionTypes.SEARCH_LOG:
                case ExportRequestActionTypes.SEARCH_TRANSACTION: {
                    return {
                        ...state,
                        searchValidationErrors: action.payload.validationErrors,
                        searchLoading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case ExportRequestActionTypes.GET_LOGS:
            return {
                ...state,
                // exportRequests: {},
                loading: true,
                error: "",
            };
        case ExportRequestActionTypes.GET_LOG:
            return {
                ...state,
                exportRequest: {},
                loading: true,
                error: "",
            };

        case ExportRequestActionTypes.RESET:
            return {
                ...state,
                transactions: {},

                exportRequest: {},
                exportRequests: {},
                queryParams: {},

                loading: false,
                error: "",
                validationErrors: {},

                searchModal: false,
                searchLoading: false,
                searchMode: false,
                searchError: "",
                searchValidationErrors: {},

                tmpExportRequests: {},
                tmpQueryParams: {},

            };


        // search actions
        case ExportRequestActionTypes.OPEN_SEARCH:
            return {
                ...state,
                searchModal: true,
            };
        case ExportRequestActionTypes.CLOSE_SEARCH:
            return {
                ...state,
                searchModal: false,
                searchLoading: false,
                searchError: "",
                searchValidationErrors: {},
            };
        case ExportRequestActionTypes.SEARCH_LOG:
            return {
                ...state,
                searchLoading: true,
                searchError: "",
                searchValidationErrors: {},
            };
        case ExportRequestActionTypes.SEARCH_TRANSACTION:
            return {
                ...state,
                searchLoading: true,
                searchError: "",
                searchValidationErrors: {},
            };
        case ExportRequestActionTypes.CLEAR_SEARCH:
            return {
                ...state,
                exportRequests: state.tmpExportRequests,
                queryParams: state.tmpQueryParams,
                tmpExportRequests: {},
                tmpQueryParams: {},
                searchMode: false,
            };

        default:
            return { ...state };
    }
};

export default ExportRequest;
