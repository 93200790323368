import {TransactionActionTypes} from './constants';

export type TransactionActionType = {
    type:
        | TransactionActionTypes.API_RESPONSE_SUCCESS
        | TransactionActionTypes.API_RESPONSE_ERROR
        | TransactionActionTypes.API_RESPONSE_VALIDATION_ERRORS
        | TransactionActionTypes.GET_TRANSACTIONS

        | TransactionActionTypes.GET_TRANSACTION

        | TransactionActionTypes.OPEN_SEARCH
        | TransactionActionTypes.CLOSE_SEARCH
        | TransactionActionTypes.SEARCH_TRANSACTION
        | TransactionActionTypes.CLEAR_SEARCH

        | TransactionActionTypes.RESET

        | TransactionActionTypes.EXPORT
        | TransactionActionTypes.RESET_SEARCH

        | TransactionActionTypes.CHART_DATA;

    payload: {} | string;
};

type Transaction = {
    id: number;
    name: string;
    guard_name: string;
};

type QueryParams = {
    page: number;
    limit: number;
    orderBy: string,
    sort: string,
    transactionNames?: string
    accountId?: string
}

class TransactionActions {

    apiResponseSuccess = (actionType: string, data: Transaction | {}): TransactionActionType => ({
        type: TransactionActionTypes.API_RESPONSE_SUCCESS,
        payload: { actionType, data },
    });

    apiResponseError = (actionType: string, error: string): TransactionActionType => ({
        type: TransactionActionTypes.API_RESPONSE_ERROR,
        payload: { actionType, error },
    });

    apiResponseValidationErrors = (actionType: string, validationErrors: []): TransactionActionType => ({
        type: TransactionActionTypes.API_RESPONSE_VALIDATION_ERRORS,
        payload: { actionType, validationErrors },
    });

    reset = (): TransactionActionType => ({
        type: TransactionActionTypes.RESET,
        payload: {},
    });

    getTransactions = (queryParams: QueryParams): TransactionActionType => ({
        type: TransactionActionTypes.GET_TRANSACTIONS,
        payload: { queryParams },
    });

    getTransaction = (id: string): TransactionActionType => ({
        type: TransactionActionTypes.GET_TRANSACTION,
        payload: { id },
    });

    openSearch = (): TransactionActionType => ({
        type: TransactionActionTypes.OPEN_SEARCH,
        payload: {}
    });

    closeSearch = (): TransactionActionType => ({
        type: TransactionActionTypes.CLOSE_SEARCH,
        payload: {}
    });

    search = (queryParams: QueryParams): TransactionActionType => ({
        type: TransactionActionTypes.SEARCH_TRANSACTION,
        payload: { queryParams }
    });

    clearSearch = (): TransactionActionType => ({
        type: TransactionActionTypes.CLEAR_SEARCH,
        payload: {}
    });

    export = (queryParams: QueryParams): TransactionActionType => ({
        type: TransactionActionTypes.EXPORT,
        payload: { queryParams }
    });

    resetSearch = (): TransactionActionType => ({
        type: TransactionActionTypes.RESET_SEARCH,
        payload: {}
    });

    getChartData = (params: { interval: string }): TransactionActionType => ({
        type: TransactionActionTypes.CHART_DATA,
        payload: { ...params }
    });

}

const transactionActions = new TransactionActions();
export { transactionActions };
