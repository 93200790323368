import {ExportRequestActionTypes} from './constants';

export type ExportRequestActionType = {
    type:
        | ExportRequestActionTypes.API_RESPONSE_SUCCESS
        | ExportRequestActionTypes.API_RESPONSE_ERROR
        | ExportRequestActionTypes.API_RESPONSE_VALIDATION_ERRORS

        | ExportRequestActionTypes.GET_LOGS
        | ExportRequestActionTypes.GET_LOG

        | ExportRequestActionTypes.OPEN_SEARCH
        | ExportRequestActionTypes.CLOSE_SEARCH
        | ExportRequestActionTypes.SEARCH_LOG
        | ExportRequestActionTypes.SEARCH_TRANSACTION
        | ExportRequestActionTypes.CLEAR_SEARCH

        | ExportRequestActionTypes.RESET;
    payload: {} | string;
};

type ExportRequest = {
    id: number;
    name: string;
    guard_name: string;
};

type QueryParams = {
    page: number;
    limit: number;
    orderBy: string,
    sort: string,
    transactionName?: string,
    accountId?: string
}

class ExportRequestActions {

    apiResponseSuccess = (actionType: string, data: ExportRequest | {}): ExportRequestActionType => ({
        type: ExportRequestActionTypes.API_RESPONSE_SUCCESS,
        payload: { actionType, data },
    });

    apiResponseError = (actionType: string, error: string): ExportRequestActionType => ({
        type: ExportRequestActionTypes.API_RESPONSE_ERROR,
        payload: { actionType, error },
    });

    apiResponseValidationErrors = (actionType: string, validationErrors: []): ExportRequestActionType => ({
        type: ExportRequestActionTypes.API_RESPONSE_VALIDATION_ERRORS,
        payload: { actionType, validationErrors },
    });

    reset = (): ExportRequestActionType => ({
        type: ExportRequestActionTypes.RESET,
        payload: {},
    });

    getExportRequests = (queryParams: QueryParams): ExportRequestActionType => ({
        type: ExportRequestActionTypes.GET_LOGS,
        payload: { queryParams },
    });

    openSearch = (): ExportRequestActionType => ({
        type: ExportRequestActionTypes.OPEN_SEARCH,
        payload: {}
    });

    closeSearch = (): ExportRequestActionType => ({
        type: ExportRequestActionTypes.CLOSE_SEARCH,
        payload: {}
    });

    search = (queryParams: QueryParams): ExportRequestActionType => ({
        type: ExportRequestActionTypes.SEARCH_LOG,
        payload: { queryParams }
    });

    searchTransaction = (queryParams: QueryParams): ExportRequestActionType => ({
        type: ExportRequestActionTypes.SEARCH_TRANSACTION,
        payload: { queryParams }
    });

    clearSearch = (): ExportRequestActionType => ({
        type: ExportRequestActionTypes.CLEAR_SEARCH,
        payload: {}
    });

}

const exportRequestActions = new ExportRequestActions();
export { exportRequestActions };
