export enum ExchangeActionTypes {
    API_RESPONSE_SUCCESS = '@@exchange/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@exchange/API_RESPONSE_ERROR',
    API_RESPONSE_VALIDATION_ERRORS = '@@exchange/API_RESPONSE_VALIDATION_ERRORS',

    GET_EXCHANGES = '@@exchange/GET_EXCHANGES',
    GET_EXCHANGE = '@@exchange/GET_EXCHANGE',
    GET_TRANSACTIONS = '@@exchange/GET_TRANSACTIONS',
    INSERT_EXCHANGE = '@@exchange/INSERT_EXCHANGE',
    USD_RATE = '@@exchange/USD_RATE',

    CHECK_EXCHANGE = '@@exchange/CHECK_EXCHANGE',

    PREVIEW_EXCHANGE_DETAIL = '@@exchange/PREVIEW_EXCHANGE_DETAIL',
    RESET_PREVIEW_DETAIL = '@@exchange/RESET_PREVIEW_DETAIL',

    CLEAR_CHECKED = '@@exchange/CLEAR_CHECKED',

    OPEN_SEARCH = '@@exchange/OPEN_SEARCH',
    CLOSE_SEARCH = '@@exchange/CLOSE_SEARCH',
    SEARCH_EXCHANGE = '@@exchange/SEARCH_EXCHANGE',
    SEARCH_TRANSACTION = '@@exchange/SEARCH_TRANSACTION',
    CLEAR_SEARCH = '@@exchange/CLEAR_SEARCH',
    EXPORT_TRANSACTIONS = '@@exchange/EXPORT_TRANSACTIONS',
    EXPORT = '@@exchange/EXPORT',
    RESET_SEARCH = '@@exchange/RESET_SEARCH',

    RESET = '@@exchange/RESET',
    CHART_DATA = '@@exchange/CHART_DATA',
}

export enum ExchangeErrors {
    RESPONSE_200 = 'Unfortunately, an error has occurred. Please try again.',
}



