export enum TransferActionTypes {
    API_RESPONSE_SUCCESS = '@@transfer/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@transfer/API_RESPONSE_ERROR',
    API_RESPONSE_VALIDATION_ERRORS = '@@transfer/API_RESPONSE_VALIDATION_ERRORS',

    GET_TRANSFERS = '@@transfer/GET_TRANSFERS',
    GET_TRANSFER = '@@transfer/GET_TRANSFER',
    GET_TRANSACTIONS = '@@transfer/GET_TRANSACTIONS',
    INSERT_TRANSFER = '@@transfer/INSERT_TRANSFER',

    CHECK_TRANSFER = '@@transfer/CHECK_TRANSFER',

    PREVIEW_TRANSFER_DETAIL = '@@transfer/PREVIEW_TRANSFER_DETAIL',
    RESET_PREVIEW_DETAIL = '@@transfer/RESET_PREVIEW_DETAIL',

    CLEAR_CHECKED = '@@transfer/CLEAR_CHECKED',

    OPEN_SEARCH = '@@transfer/OPEN_SEARCH',
    CLOSE_SEARCH = '@@transfer/CLOSE_SEARCH',
    SEARCH_TRANSFER = '@@transfer/SEARCH_TRANSFER',
    SEARCH_TRANSACTION = '@@transfer/SEARCH_TRANSACTION',
    CLEAR_SEARCH = '@@transfer/CLEAR_SEARCH',
    RESET_SEARCH = '@@transfer/RESET_SEARCH',

    RESET = '@@transfer/RESET',

    EXPORT = '@@transfer/EXPORT',
    EXPORT_TRANSACTIONS = '@@transfer/EXPORT_TRANSACTIONS',

    CHART_DATA = '@@transfer/CHART_DATA',
}

export enum TransferErrors {
    RESPONSE_200 = 'Unfortunately, an error has occurred. Please try again.',
}



