import { OfflineDepositActionTypes } from './constants';
import {isEmptyObject} from "../../helpers/functions";

const INIT_STATE = {
    deposits: {},
    queryParams: {},

    deposit: {},

    error: {},
    validationErrors: {},
    loading: {},

    depositInserted: false,
    transactionVerified: false,

    searchModal: false,
    tmpDeposits: {},
    tmpQueryParams: {},
    searchMode: false,

    exportUrl: "",

    options: {},

    approved: false
};

type DepositQueryParams = {
    limit: number;
    page: number;
    orderBy: string;
    sort: string;
    depositName: string;
};

type DepositsResult = {
    deposits: any
    queryParams: DepositQueryParams
}

type DepositActionType = {
    type:
        | OfflineDepositActionTypes.API_RESPONSE_SUCCESS
        | OfflineDepositActionTypes.API_RESPONSE_ERROR
        | OfflineDepositActionTypes.API_RESPONSE_VALIDATION_ERRORS
        | OfflineDepositActionTypes.GET_DEPOSITS
        | OfflineDepositActionTypes.GET_DEPOSIT
        | OfflineDepositActionTypes.INSERT_DEPOSIT
        | OfflineDepositActionTypes.RESET

        | OfflineDepositActionTypes.OPEN_SEARCH
        | OfflineDepositActionTypes.CLOSE_SEARCH
        | OfflineDepositActionTypes.SEARCH_DEPOSIT
        | OfflineDepositActionTypes.CLEAR_SEARCH
        | OfflineDepositActionTypes.RESET_SEARCH

        | OfflineDepositActionTypes.EXPORT

        | OfflineDepositActionTypes.GET_OPTIONS
        | OfflineDepositActionTypes.APPROVE
        | OfflineDepositActionTypes.RESET_VALIDATION_ERROR;

    payload: {
        actionType?: string;
        data?: DepositsResult | any;
        error?: string;
        validationErrors?: [];

        key?: any
        value?: any
    };
};

type State = {
    deposits?: any;

    queryParams?: {};
    value?: boolean;
    tmpDeposits?: any;

    tmpQueryParams?: any;

    loading: {},
    error: {},
    validationErrors: {},
};

const OfflineDeposit = (state: State = INIT_STATE, action: DepositActionType) => {
    switch (action.type) {
        case OfflineDepositActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case OfflineDepositActionTypes.GET_DEPOSITS: {
                    return {
                        ...state,
                        deposits: action.payload.data?.deposits,
                        queryParams: action.payload.data?.queryParams,
                        loading: {
                            deposits: false
                        },
                    };
                }
                case OfflineDepositActionTypes.GET_DEPOSIT: {
                    return {
                        ...state,
                        deposit: action.payload.data,
                        loading: {
                            deposit: false
                        },
                    };
                }
                case OfflineDepositActionTypes.INSERT_DEPOSIT: {
                    return {
                        ...state,
                        depositInserted: action.payload.data,
                        loading: {
                            insert: false
                        },
                    };
                }
                case OfflineDepositActionTypes.APPROVE: {
                    return {
                        ...state,
                        approved: action.payload.data,
                        loading: {
                            approve: false
                        },
                    };
                }
                case OfflineDepositActionTypes.SEARCH_DEPOSIT: {
                    return {
                        ...state,
                        // this condition used for prevent multiple search
                        tmpDeposits: isEmptyObject(state.tmpDeposits)? state.deposits: state.tmpDeposits,
                        tmpQueryParams: isEmptyObject(state.tmpQueryParams)? state.queryParams: state.tmpQueryParams,

                        deposits: action.payload.data?.deposits,
                        queryParams: action.payload.data?.queryParams,

                        searchMode: true,
                        searchModal: false,

                        loading: {
                            search: false
                        },
                    };
                }
                case OfflineDepositActionTypes.EXPORT: {
                    return {
                        ...state,
                        exportUrl: action.payload.data,

                        searchMode: false,
                        searchModal: false,

                        loading: {
                            export: false,
                            search: false
                        },
                    };
                }
                case OfflineDepositActionTypes.GET_OPTIONS: {
                    return {
                        ...state,
                        options: action.payload.data,
                        loading: {
                            getOption: false
                        },
                    };
                }
                default:
                    return { ...state };
            }

        case OfflineDepositActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case OfflineDepositActionTypes.GET_DEPOSITS:
                {
                    return {
                        ...state,
                        error: {
                            "deposits": action.payload.error
                        },
                        loading: {
                            "deposits": false
                        },
                    };
                }
                case OfflineDepositActionTypes.GET_DEPOSIT:
                {
                    return {
                        ...state,
                        error: {
                            "deposit": action.payload.error
                        },
                        loading: {
                            "deposit": false
                        },
                    };
                }
                case OfflineDepositActionTypes.INSERT_DEPOSIT:
                {
                    return {
                        ...state,
                        error: {
                            "insert": action.payload.error
                        },
                        loading: {
                            "insert": false
                        },
                    };
                }
                case OfflineDepositActionTypes.APPROVE:
                {
                    return {
                        ...state,
                        error: {
                            "approve": action.payload.error
                        },
                        loading: {
                            "approve": false
                        },
                    };
                }
                case OfflineDepositActionTypes.SEARCH_DEPOSIT: {
                    return {
                        ...state,
                        error: {
                            "search": action.payload.error
                        },
                        loading: {
                            "search": false
                        },
                    };
                }
                case OfflineDepositActionTypes.EXPORT: {
                    return {
                        ...state,
                        error: {
                            "export": action.payload.error
                        },
                        loading: {
                            "export": false
                        },
                    };
                }
                case OfflineDepositActionTypes.GET_OPTIONS:
                {
                    return {
                        ...state,
                        error: {
                            "getOption": action.payload.error
                        },
                        loading: {
                            "getOption": false
                        },
                    };
                }
                default:
                    return { ...state };
            }

        case OfflineDepositActionTypes.API_RESPONSE_VALIDATION_ERRORS:
            switch (action.payload.actionType) {
                case OfflineDepositActionTypes.GET_DEPOSITS: {
                    return {
                        ...state,
                        error: {
                            "deposits": "Invalid input error"
                        },
                        loading: {
                            "deposits": false
                        },
                    };
                }
                case OfflineDepositActionTypes.GET_DEPOSIT:
                {
                    return {
                        ...state,
                        validationErrors: {
                            "deposit": action.payload.validationErrors
                        },
                        loading: {
                            "deposit": false
                        },
                    };
                }
                case OfflineDepositActionTypes.INSERT_DEPOSIT:
                {
                    return {
                        ...state,
                        validationErrors: {
                            "insert": action.payload.validationErrors
                        },
                        loading: {
                            "insert": false
                        },
                    };
                }
                case OfflineDepositActionTypes.APPROVE:
                {
                    return {
                        ...state,
                        validationErrors: {
                            "approve": action.payload.validationErrors
                        },
                        loading: {
                            "approve": false
                        },
                    };
                }
                case OfflineDepositActionTypes.SEARCH_DEPOSIT: {
                    return {
                        ...state,
                        validationErrors: {
                            "search": action.payload.validationErrors
                        },
                        loading: {
                            "search": false
                        },
                    };
                }
                case OfflineDepositActionTypes.EXPORT: {
                    return {
                        ...state,
                        validationErrors: {
                            "export": action.payload.validationErrors
                        },
                        loading: {
                            "export": false
                        },
                    };
                }
                case OfflineDepositActionTypes.GET_OPTIONS:
                {
                    return {
                        ...state,
                        validationErrors: {
                            "getOption": action.payload.validationErrors
                        },
                        loading: {
                            "getOption": false
                        },
                    };
                }
                default:
                    return { ...state };
            }

        case OfflineDepositActionTypes.GET_DEPOSITS:
            return {
                ...state,
                // deposits: {},
                error: {
                    "deposits": ""
                },
                loading: {
                    "deposits": true
                },
            };

        case OfflineDepositActionTypes.GET_DEPOSIT:
            return {
                ...state,
                deposit: {},
                error: {
                    "deposit": ""
                },
                loading: {
                    "deposit": true
                },
            };

        case OfflineDepositActionTypes.INSERT_DEPOSIT:
            return {
                ...state,
                depositInserted: false,

                error: {
                    "insert": ""
                },
                loading: {
                    "insert": true
                },
            };

        case OfflineDepositActionTypes.APPROVE:
            return {
                ...state,
                approved: false,

                error: {
                    "approve": ""
                },
                loading: {
                    "approve": true
                },
            };

        case OfflineDepositActionTypes.RESET:
            return {
                ...state,
                deposits: {},
                queryParams: {},

                deposit: {},

                error: {},
                loading: {},
                validationErrors: {},

                depositInserted: false,
                transactionVerified: false,

                searchModal: false,
                searchMode: false,

                tmpDeposits: {},
                tmpQueryParams: {},

                exportUrl: "",

                options: {},

                approved: false
            };

        // search actions
        case OfflineDepositActionTypes.OPEN_SEARCH:
            return {
                ...state,
                searchModal: true,
            };
        case OfflineDepositActionTypes.CLOSE_SEARCH:
            return {
                ...state,
                searchModal: false,

                loading: {
                    ...state.loading,
                    search: false,
                },

                error: {
                    ...state.error,
                    search: false
                },

                validationErrors: {
                    ...state.validationErrors,
                    search: false
                }
            };
        case OfflineDepositActionTypes.SEARCH_DEPOSIT:
            return {
                ...state,

                loading: {
                    ...state.loading,
                    search: true,
                },

                error: {
                    ...state.error,
                    search: false
                },

                validationErrors: {
                    ...state.validationErrors,
                    search: false
                }
            };

        case OfflineDepositActionTypes.CLEAR_SEARCH:
            return {
                ...state,
                deposits: state.tmpDeposits,
                queryParams: state.tmpQueryParams,
                tmpDeposits: {},
                tmpQueryParams: {},
                searchMode: false,
            };

        case OfflineDepositActionTypes.EXPORT:

            return {
                ...state,
                exportUrl: "",

                loading: {
                    ...state.loading,
                    export: false,
                },

                error: {
                    ...state.error,
                    export: false
                },

                validationErrors: {
                    ...state.validationErrors,
                    export: false
                }
            };

        case OfflineDepositActionTypes.RESET_SEARCH:

            return {
                ...state,
                searchModal: false,
                exportUrl: "",

                loading: {
                    ...state.loading,
                    export: false,
                    search: false,
                },

                error: {
                    ...state.error,
                    export: false,
                    search: false
                },

                validationErrors: {
                    ...state.validationErrors,
                    export: false,
                    search: false,
                }
            }

        case OfflineDepositActionTypes.GET_OPTIONS:
            const { getOption, ...remainingErrorsForGetSetting } = state.error as { [key: string]: any };
            return {
                ...state,
                options: {},
                loading: {
                    getOption: true
                },
                error: remainingErrorsForGetSetting,
            };

        case OfflineDepositActionTypes.RESET_VALIDATION_ERROR:
            return {
                ...state,
                validationErrors: {},
            };

        default:
            return { ...state };
    }
};

export default OfflineDeposit;
