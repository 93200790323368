import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import { graphql } from 'helpers';
import {ExportRequestActionTypes, ExportRequestErrors} from './constants';
import {
    checkResponseError,
    checkServerError,
    pluckResponse,
} from "../../helpers/functions";
import {exportRequestActions} from "./actions";

type ExportRequestData = {
    payload: {

        queryParams: {
            limit: string;
            page: string;
            orderBy: string;
            sort: string;
        };

        data: any;
        id: any;
    };
    type: string;
};

function* getExportRequests({ payload: { queryParams } }: ExportRequestData): SagaIterator {

    try {
        const param = {
            query:`query ExportRequests($page: Int!, $limit: Int, $orderBy: String!, $sort: String!, $accountId: String) {
                exportRequests(page: $page, limit: $limit, orderBy: $orderBy, sort: $sort, accountId: $accountId) {
                    data {
                        name
                        status
                        user {
                            display_name
                        }
                        url
                        created_at
                    }
                    total
                    per_page
                    from
                    to
                    current_page
                    last_page
                    has_more_pages
                }
            }`,

            variables: {
                ...queryParams
            },
            operationName: "ExportRequests"
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, ExportRequestErrors.RESPONSE_200);

        const rExportRequests = response.data;

        checkServerError(rExportRequests);

        const exportRequests = pluckResponse(rExportRequests, "exportRequests");

        const result = {
            exportRequests,
            queryParams
        }

        yield put(exportRequestActions.apiResponseSuccess(ExportRequestActionTypes.GET_LOGS, result));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(exportRequestActions.apiResponseError(ExportRequestActionTypes.GET_LOGS, error));
        } else {
            yield put(exportRequestActions.apiResponseValidationErrors(ExportRequestActionTypes.GET_LOGS, error));
        }
    }
}

function* getExportRequest({ payload: { id } }: ExportRequestData): SagaIterator {

    try {
        const param = {
            query:`query ExportRequest($id: String!) {
                exportRequest(id: $id) {
                    name
                    status
                    user {
                        display_name
                    }
                    created_at
                }
            }`,

            variables: {
                id: id
            },
            operationName: "ExportRequest"
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, ExportRequestErrors.RESPONSE_200);

        const rT = response.data;

        checkServerError(rT);

        const exportRequest = pluckResponse(rT, "exportRequest");

        yield put(exportRequestActions.apiResponseSuccess(ExportRequestActionTypes.GET_LOG, exportRequest));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(exportRequestActions.apiResponseError(ExportRequestActionTypes.GET_LOG, error));
        } else {
            yield put(exportRequestActions.apiResponseValidationErrors(ExportRequestActionTypes.GET_LOG, error));
        }
    }
}

function* searchExportRequest({ payload: { queryParams } }: ExportRequestData): SagaIterator {
    try {
        const param = {
            query:`query ExportRequests(
                $page: Int!, 
                $limit: Int, 
                $orderBy: String!, 
                $sort: String!, 
                $accountId: String
            ) {
                exportRequests(
                    page: $page, 
                    limit: $limit, 
                    orderBy: $orderBy, 
                    sort: $sort,
                    accountId: $accountId
                ) {
                    data {
                        name
                        status
                        user {
                            display_name
                        }
                        created_at
                    }
                    total
                    per_page
                    from
                    to
                    current_page
                    last_page
                    has_more_pages
                }
            }`,

            variables: {
                ...queryParams
            },
            operationName: "ExportRequests"
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, ExportRequestErrors.RESPONSE_200);

        const rExportRequests = response.data;

        checkServerError(rExportRequests);

        const exportRequests = pluckResponse(rExportRequests, "exportRequests");

        const result = {
            exportRequests,
            queryParams
        }

        yield put(exportRequestActions.apiResponseSuccess(ExportRequestActionTypes.SEARCH_LOG, result));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(exportRequestActions.apiResponseError(ExportRequestActionTypes.SEARCH_LOG, error));
        } else {
            yield put(exportRequestActions.apiResponseValidationErrors(ExportRequestActionTypes.SEARCH_LOG, error));
        }
    }
}


export function* watchGetExportRequests() {
    yield takeEvery(ExportRequestActionTypes.GET_LOGS, getExportRequests);
}

export function* watchGetExportRequest() {
    yield takeEvery(ExportRequestActionTypes.GET_LOG, getExportRequest);
}

export function* watchSearchExportRequest() {
    yield takeEvery(ExportRequestActionTypes.SEARCH_LOG, searchExportRequest);
}


function* exportRequestsSaga() {
    yield all([
        fork(watchGetExportRequests),
        fork(watchGetExportRequest),
        fork(watchSearchExportRequest),
    ]);
}

export default exportRequestsSaga;
