import { TransferActionTypes } from './constants';
import {isEmptyObject} from "../../helpers/functions";

const INIT_STATE = {
    transactions: {},
    transfers: {},
    transfer: {},
    queryParams: {},

    loading: false,
    error: "",
    validationErrors: {},

    transferInserted: false,
    transferChecked: false,

    searchModal: false,
    tmpTransfers: {},
    tmpQueryParams: {},
    searchMode: false,
    searchLoading: false,
    searchError: "",
    searchValidationErrors: {},

    tmpTransactions: {},

    previewDetail: {},
    previewDetailLoading: false,
    previewDetailError: "",

    exportUrl: "",
    exportLoading: false,
    exportError: "",
    exportValidationErrors: [],

    chartData: [],
    chartError: "",
    chartLoading: false,
    chartValidationErrors: {}
};


type TransferActionType = {
    type:
        | TransferActionTypes.API_RESPONSE_SUCCESS
        | TransferActionTypes.API_RESPONSE_ERROR
        | TransferActionTypes.API_RESPONSE_VALIDATION_ERRORS
        | TransferActionTypes.GET_TRANSACTIONS
        | TransferActionTypes.GET_TRANSFERS
        | TransferActionTypes.GET_TRANSFER
        | TransferActionTypes.INSERT_TRANSFER
        | TransferActionTypes.CHECK_TRANSFER
        | TransferActionTypes.RESET

        | TransferActionTypes.PREVIEW_TRANSFER_DETAIL
        | TransferActionTypes.RESET_PREVIEW_DETAIL

        | TransferActionTypes.OPEN_SEARCH
        | TransferActionTypes.CLOSE_SEARCH
        | TransferActionTypes.SEARCH_TRANSFER
        | TransferActionTypes.SEARCH_TRANSACTION
        | TransferActionTypes.CLEAR_SEARCH
        | TransferActionTypes.RESET_SEARCH

        | TransferActionTypes.CLEAR_CHECKED
        | TransferActionTypes.EXPORT
        | TransferActionTypes.EXPORT_TRANSACTIONS
        | TransferActionTypes.CHART_DATA;

    payload: {
        actionType?: string;
        data?: any;
        error?: string;
        validationErrors?: [];
    };
};

type State = {
    transfers?: {};

    queryParams?: {};

    loading?: boolean;
    value?: boolean;
    tmpTransfers?: any;

    tmpQueryParams?: any;

    transactions?: any;
    tmpTransactions?: any;
};

const Transfer = (state: State = INIT_STATE, action: TransferActionType) => {
    switch (action.type) {
        case TransferActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case TransferActionTypes.GET_TRANSFERS: {
                    return {
                        ...state,
                        transfers: action.payload.data?.transfers,
                        queryParams: action.payload.data?.queryParams,
                        loading: false,
                    };
                }
                case TransferActionTypes.GET_TRANSFER: {
                    return {
                        ...state,
                        transfer: action.payload.data,
                        loading: false,
                    };
                }
                case TransferActionTypes.GET_TRANSACTIONS: {
                    return {
                        ...state,
                        transactions: action.payload.data?.transactions,
                        queryParams: action.payload.data?.queryParams,
                        loading: false,
                    };
                }
                case TransferActionTypes.CHECK_TRANSFER: {
                    return {
                        ...state,
                        transferChecked: action.payload.data,
                        loading: false,
                    };
                }
                case TransferActionTypes.INSERT_TRANSFER: {
                    return {
                        ...state,
                        transferInserted: action.payload.data,
                        loading: false,
                    };
                }
                case TransferActionTypes.PREVIEW_TRANSFER_DETAIL: {
                    return {
                        ...state,
                        previewDetail: action.payload.data,
                        previewDetailLoading: false,
                    };
                }
                case TransferActionTypes.SEARCH_TRANSFER: {
                    return {
                        ...state,
                        // this condition used for prevent multiple search
                        tmpTransfers: isEmptyObject(state.tmpTransfers)? state.transfers: state.tmpTransfers,
                        tmpQueryParams: isEmptyObject(state.tmpQueryParams)? state.queryParams: state.tmpQueryParams,

                        transfers: action.payload.data?.transfers,
                        queryParams: action.payload.data?.queryParams,

                        searchMode: true,
                        searchModal: false,
                        searchLoading: false,
                    };
                }
                case TransferActionTypes.EXPORT:
                case TransferActionTypes.EXPORT_TRANSACTIONS: {
                    return {
                        ...state,
                        exportUrl: action.payload.data,
                        exportLoading: false,

                        searchMode: false,
                        searchModal: false,
                        searchLoading: false,
                    };
                }
                case TransferActionTypes.SEARCH_TRANSACTION: {
                    return {
                        ...state,
                        // this condition used for prevent multiple search
                        tmpTransactions: isEmptyObject(state.tmpTransactions)? state.transactions: state.tmpTransactions,
                        tmpQueryParams: isEmptyObject(state.tmpQueryParams)? state.queryParams: state.tmpQueryParams,

                        transactions: action.payload.data?.transactions,
                        queryParams: action.payload.data?.queryParams,

                        searchMode: true,
                        searchModal: false,
                        searchLoading: false,
                    };
                }
                case TransferActionTypes.CHART_DATA: {
                    return {
                        ...state,
                        loading: false,
                        chartData: action.payload.data
                    };
                }
                default:
                    return { ...state };
            }

        case TransferActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case TransferActionTypes.GET_TRANSFERS:
                case TransferActionTypes.GET_TRANSFER:
                case TransferActionTypes.CHECK_TRANSFER:
                case TransferActionTypes.INSERT_TRANSFER:
                case TransferActionTypes.GET_TRANSACTIONS: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }
                case TransferActionTypes.PREVIEW_TRANSFER_DETAIL: {
                    return {
                        ...state,
                        previewDetailError: action.payload.error,
                        previewDetailLoading: false,
                    };
                }
                case TransferActionTypes.SEARCH_TRANSFER:
                case TransferActionTypes.SEARCH_TRANSACTION: {
                    return {
                        ...state,
                        searchError: action.payload.error,
                        searchLoading: false,
                    };
                }
                case TransferActionTypes.EXPORT_TRANSACTIONS:
                case TransferActionTypes.EXPORT: {
                    return {
                        ...state,
                        exportError: action.payload.error,
                        exportLoading: false,
                    };
                }
                case TransferActionTypes.CHART_DATA: {
                    return {
                        ...state,
                        chartError: action.payload.error,
                        chartLoading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case TransferActionTypes.API_RESPONSE_VALIDATION_ERRORS:

            switch (action.payload.actionType) {
                case TransferActionTypes.GET_TRANSFERS:
                case TransferActionTypes.GET_TRANSACTIONS: {
                    return {
                        ...state,
                        error: "Invalid input error",
                        loading: false,
                    };
                }
                case TransferActionTypes.GET_TRANSFER:
                case TransferActionTypes.CHECK_TRANSFER:
                case TransferActionTypes.INSERT_TRANSFER:
                case TransferActionTypes.PREVIEW_TRANSFER_DETAIL:
                {
                    return {
                        ...state,
                        validationErrors: action.payload.validationErrors,
                        loading: false,
                    };
                }
                case TransferActionTypes.SEARCH_TRANSFER:
                case TransferActionTypes.SEARCH_TRANSACTION: {
                    return {
                        ...state,
                        searchValidationErrors: action.payload.validationErrors,
                        searchLoading: false,
                    };
                }
                case TransferActionTypes.EXPORT_TRANSACTIONS:
                case TransferActionTypes.EXPORT: {
                    return {
                        ...state,
                        exportValidationErrors: action.payload.validationErrors,
                        exportLoading: false,
                    };
                }
                case TransferActionTypes.CHART_DATA: {
                    return {
                        ...state,
                        chartValidationErrors: action.payload.validationErrors,
                        chartLoading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case TransferActionTypes.GET_TRANSFERS:
            return {
                ...state,
                // transfers: {},
                loading: true,
                error: "",
            };
        case TransferActionTypes.GET_TRANSFER:
            return {
                ...state,
                transfer: {},
                loading: true,
                error: "",
            };

        case TransferActionTypes.GET_TRANSACTIONS:
            return {
                ...state,
                // transactions: {},
                loading: true,
                error: "",
            };

        case TransferActionTypes.CHECK_TRANSFER:
            return {
                ...state,
                transferChecked: false,
                loading: true,
                error: "",
            };

        case TransferActionTypes.INSERT_TRANSFER:
            return {
                ...state,
                transferInserted: false,
                loading: true,
                error: "",
            };

        case TransferActionTypes.RESET:
            return {
                ...state,
                transactions: {},

                transfer: {},
                transfers: {},
                queryParams: {},

                loading: false,
                error: "",
                validationErrors: {},

                transferChecked: false,
                transferInserted: false,

                searchModal: false,
                searchLoading: false,
                searchMode: false,
                searchError: "",
                searchValidationErrors: {},

                tmpTransfers: {},
                tmpQueryParams: {},

                tmpTransactions: {},

                previewDetail: {},
                previewDetailLoading: false,
                previewDetailError: "",

                exportUrl: "",
                exportLoading: false,
                exportError: "",
                exportValidationErrors: [],

                chartData: [],
                chartError: "",
                chartLoading: false,
                chartValidationErrors: {}
            };


        // search actions
        case TransferActionTypes.OPEN_SEARCH:
            return {
                ...state,
                searchModal: true,
            };
        case TransferActionTypes.CLOSE_SEARCH:
            return {
                ...state,
                searchModal: false,
                searchLoading: false,
                searchError: "",
                searchValidationErrors: {},
            };
        case TransferActionTypes.SEARCH_TRANSFER:
            return {
                ...state,
                searchLoading: true,
                searchError: "",
                searchValidationErrors: {},
            };
        case TransferActionTypes.SEARCH_TRANSACTION:
            return {
                ...state,
                searchLoading: true,
                searchError: "",
                searchValidationErrors: {},
            };
        case TransferActionTypes.CLEAR_SEARCH:
            return {
                ...state,
                transfers: state.tmpTransfers,
                transactions: state.tmpTransactions,
                queryParams: state.tmpQueryParams,
                tmpTransfers: {},
                tmpTransactions: {},
                tmpQueryParams: {},
                searchMode: false,
            };

        case TransferActionTypes.EXPORT_TRANSACTIONS:

            return {
                ...state,
                exportUrl: "",
                exportLoading: true,
                exportError: "",
                exportValidationErrors: [],
            };

        case TransferActionTypes.EXPORT:

            return {
                ...state,
                exportUrl: "",
                exportLoading: true,
                exportError: "",
                exportValidationErrors: [],
            };

        case TransferActionTypes.RESET_SEARCH:

            return {
                ...state,
                searchModal: false,
                searchLoading: false,
                searchError: "",
                searchValidationErrors: {},

                exportUrl: "",
                exportLoading: false,
                exportError: "",
                exportValidationErrors: [],
            }

        case TransferActionTypes.PREVIEW_TRANSFER_DETAIL:
            return {
                ...state,
                previewDetail: {},
                previewDetailLoading: true,
                previewDetailError: "",
            };
        case TransferActionTypes.RESET_PREVIEW_DETAIL:
            return {
                ...state,
                previewDetail: {},
                previewDetailLoading: false,
                previewDetailError: "",
            };

        case TransferActionTypes.CLEAR_CHECKED:
            return {
                ...state,
                transferChecked: false,
            };

        case TransferActionTypes.CHART_DATA: {
            return {
                ...state,
                chartError: "",
                chartLoading: false,
            };
        }

        default:
            return { ...state };
    }
};

export default Transfer;
