import { OrderActionTypes } from './constants';
import { isEmptyObject } from '../../helpers/functions';

const INIT_STATE = {
    loading: {},
    error: {},
    validationErrors: {},

    orders: {},
    order: {},
    inserted: false,
    updated: false,
    deleted: false,

    searchModal: false,
    tmpOrders: {},
    tmpQueryParams: {},
    searchMode: false,

    previewDetail: {},

    paymentStep1Done: false,
    paymentStep2Done: false,

    billCreated: false,
    billUpdated: false,

    rates: [],

    orderBills: [],
    orderBill: {},

    noteCreated: false,

    option: {}
};

type OrderActionType = {
    type:
        | OrderActionTypes.API_RESPONSE_SUCCESS
        | OrderActionTypes.API_RESPONSE_ERROR
        | OrderActionTypes.API_RESPONSE_VALIDATION_ERRORS
        | OrderActionTypes.ORDERS
        | OrderActionTypes.ORDER
        | OrderActionTypes.CREATE
        | OrderActionTypes.UPDATE
        | OrderActionTypes.DELETE
        | OrderActionTypes.RESET
        | OrderActionTypes.RATES
        | OrderActionTypes.PREVIEW_PAYMENT_DETAIL
        | OrderActionTypes.RESET_PREVIEW_DETAIL
        | OrderActionTypes.PAYMENT_STEP_1
        | OrderActionTypes.PAYMENT_STEP_2
        | OrderActionTypes.CREATE_BILL
        | OrderActionTypes.UPDATE_BILL
        | OrderActionTypes.ORDER_BILLS
        | OrderActionTypes.ORDER_BILL
        | OrderActionTypes.CREATE_NOTE
        | OrderActionTypes.OPEN_SEARCH
        | OrderActionTypes.CLOSE_SEARCH
        | OrderActionTypes.SEARCH
        | OrderActionTypes.CLEAR_SEARCH
        | OrderActionTypes.EXPORT
        | OrderActionTypes.RESET_SEARCH
        | OrderActionTypes.GET_OPTIONS;

    payload: {
        actionType?: string;
        data?: any;
        error?: string;
        validationErrors?: [];

        key: string;
        value?: string;

        page?: string;
    };
};

type State = {
    loading: {},
    error: {},
    validationErrors: {},

    orders: any,
    queryParams?: {};

    tmpOrders?: any;
    tmpQueryParams?: any;
};

const Order = (state: State = INIT_STATE, action: OrderActionType) => {
    switch (action.type) {
        case OrderActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {

                case OrderActionTypes.ORDERS: {
                    return {
                        ...state,
                        orders: action.payload.data?.orders,
                        queryParams: action.payload.data?.queryParams,
                        loading: {
                            "orders": false
                        },
                    };
                }

                case OrderActionTypes.ORDER: {
                    return {
                        ...state,
                        order: action.payload.data,

                        loading: {
                            "order": false
                        },
                    };
                }
                case OrderActionTypes.SEARCH: {
                    return {
                        ...state,
                        // this condition used for prevent multiple search
                        tmpOrders: isEmptyObject(state.tmpOrders)? state.orders: state.tmpOrders,
                        tmpQueryParams: isEmptyObject(state.tmpQueryParams)? state.queryParams: state.tmpQueryParams,

                        orders: action.payload.data?.orders,
                        queryParams: action.payload.data?.queryParams,

                        searchMode: true,
                        searchModal: false,

                        loading: {
                            "search": false
                        },
                    };
                }

                case OrderActionTypes.CREATE: {
                    return {
                        ...state,
                        inserted: true,
                        loading: {
                            "create": false
                        },
                    };
                }

                case OrderActionTypes.UPDATE: {
                    return {
                        ...state,
                        updated: true,
                        loading: {
                            "update": false
                        },
                    };
                }

                case OrderActionTypes.DELETE: {
                    return {
                        ...state,
                        deleted: true,
                        loading: {
                            "delete": false
                        },
                        orders: state.orders.filter(function(item: any) {
                            return item.id !== action.payload.data
                        })
                    };
                }
                case OrderActionTypes.RATES: {
                    return {
                        ...state,
                        rates: action.payload.data,
                        loading: {
                            "rates": false
                        },
                    };
                }
                case OrderActionTypes.PREVIEW_PAYMENT_DETAIL: {
                    return {
                        ...state,
                        previewDetail: action.payload.data,
                        loading: {
                            "previewDetail": false
                        },
                    };
                }
                case OrderActionTypes.PAYMENT_STEP_1: {
                    return {
                        ...state,
                        paymentStep1Done: action.payload.data,
                        loading: {
                            "paymentStep1": false
                        },
                    };
                }
                case OrderActionTypes.PAYMENT_STEP_2: {
                    return {
                        ...state,
                        paymentStep2Done: action.payload.data,
                        loading: {
                            "paymentStep2": false
                        },
                    };
                }
                case OrderActionTypes.CREATE_BILL: {
                    return {
                        ...state,
                        billCreated: action.payload.data,
                        loading: {
                            "createBill": false
                        },
                    };
                }
                case OrderActionTypes.UPDATE_BILL: {
                    return {
                        ...state,
                        billUpdated: action.payload.data,
                        loading: {
                            "updateBill": false
                        },
                    };
                }
                case OrderActionTypes.ORDER_BILLS: {
                    return {
                        ...state,
                        orderBills: action.payload.data,
                        loading: {
                            "orderBills": false
                        },
                    };
                }

                case OrderActionTypes.ORDER_BILL: {
                    return {
                        ...state,
                        orderBill: action.payload.data,
                        loading: {
                            "orderBill": false
                        },
                    };
                }
                case OrderActionTypes.CREATE_NOTE: {
                    return {
                        ...state,
                        loading: {
                            "create": false
                        },
                        noteCreated: action.payload.data,
                    };
                }
                case OrderActionTypes.EXPORT: {
                    return {
                        ...state,
                        exportUrl: action.payload.data,

                        searchMode: false,
                        searchModal: false,

                        loading: {
                            "export": false,
                            "orders": false
                        }
                    };
                }

                case OrderActionTypes.GET_OPTIONS: {
                    return {
                        ...state,
                        option: action.payload.data,
                        loading: {
                            "getOption": false
                        },
                        error: {
                            "getOption": ""
                        }
                    };
                }
                default:
                    return { ...state };
            }

        case OrderActionTypes.API_RESPONSE_ERROR:

            switch (action.payload.actionType) {

                case OrderActionTypes.ORDERS: {
                    return {
                        ...state,
                        loading: {
                            "orders": false
                        },
                        error: {
                            "orders": action.payload.error
                        }
                    };
                }
                case OrderActionTypes.ORDER: {
                    return {
                        ...state,
                        loading: {
                            "order": false
                        },
                        error: {
                            "order": action.payload.error
                        }
                    };
                }
                case OrderActionTypes.CREATE: {
                    return {
                        ...state,
                        loading: {
                            "create": false
                        },
                        error: {
                            "create": action.payload.error
                        }
                    };
                }
                case OrderActionTypes.UPDATE: {
                    return {
                        ...state,
                        loading: {
                            "update": false
                        },
                        error: {
                            "update": action.payload.error
                        }
                    };
                }
                case OrderActionTypes.DELETE: {
                    return {
                        ...state,
                        loading: {
                            "delete": false
                        },
                        error: {
                            "delete": action.payload.error
                        }
                    };
                }
                case OrderActionTypes.RATES: {
                    return {
                        ...state,
                        loading: {
                            "rates": false
                        },
                        error: {
                            "rates": action.payload.error
                        }
                    };
                }
                case OrderActionTypes.PAYMENT_STEP_1: {
                    return {
                        ...state,
                        loading: {
                            "paymentStep1": false
                        },
                        error: {
                            "paymentStep1": action.payload.error
                        }
                    };
                }
                case OrderActionTypes.PAYMENT_STEP_2: {
                    return {
                        ...state,
                        loading: {
                            "paymentStep2": false
                        },
                        error: {
                            "paymentStep2": action.payload.error
                        }
                    };
                }
                case OrderActionTypes.CREATE_BILL: {
                    return {
                        ...state,
                        loading: {
                            "createBill": false
                        },
                        error: {
                            "createBill": action.payload.error
                        }
                    };
                }
                case OrderActionTypes.UPDATE_BILL: {
                    return {
                        ...state,
                        loading: {
                            "updateBill": false
                        },
                        error: {
                            "updateBill": action.payload.error
                        }
                    };
                }
                case OrderActionTypes.ORDER_BILLS: {
                    return {
                        ...state,
                        loading: {
                            "orderBills": false
                        },
                        error: {
                            "orderBills": action.payload.error
                        }
                    };
                }
                case OrderActionTypes.ORDER_BILL: {
                    return {
                        ...state,
                        loading: {
                            "orderBill": false
                        },
                        error: {
                            "orderBill": action.payload.error
                        }
                    };
                }
                case OrderActionTypes.CREATE_NOTE: {
                    return {
                        ...state,
                        loading: {
                            "createNote": false
                        },
                        error: {
                            "createNote": action.payload.error
                        }
                    };
                }
                case OrderActionTypes.SEARCH: {
                    return {
                        ...state,
                        loading: {
                            "search": false
                        },
                        error: {
                            "search": action.payload.error
                        }
                    };
                }
                case OrderActionTypes.EXPORT: {
                    return {
                        ...state,
                        loading: {
                            "export": false
                        },
                        error: {
                            "export": action.payload.error
                        }
                    };
                }
                case OrderActionTypes.PREVIEW_PAYMENT_DETAIL:
                {
                    return {
                        ...state,
                        loading: {
                            "previewDetail": false
                        },
                        error: {
                            "previewDetail": action.payload.error
                        }
                    };
                }
                case OrderActionTypes.GET_OPTIONS:
                {
                    return {
                        ...state,
                        error: {
                            "getOption": action.payload.error
                        },
                        loading: {
                            "getOption": false
                        },
                    };
                }
                default:
                    return { ...state };
            }

        case OrderActionTypes.API_RESPONSE_VALIDATION_ERRORS:

            switch (action.payload.actionType) {
                case OrderActionTypes.ORDERS: {
                    return {
                        ...state,
                        loading: {
                            "orders": false
                        },
                        error: {
                            "orders": "Invalid input error"
                        }
                    };
                }
                case OrderActionTypes.ORDER: {
                    return {
                        ...state,
                        loading: {
                            "order": false
                        },
                        validationErrors: {
                            "order": action.payload.validationErrors
                        }
                    };
                }
                case OrderActionTypes.CREATE: {
                    return {
                        ...state,
                        loading: {
                            "create": false
                        },
                        validationErrors: {
                            "create": action.payload.validationErrors
                        }
                    };
                }
                case OrderActionTypes.UPDATE: {
                    return {
                        ...state,
                        loading: {
                            "update": false
                        },
                        validationErrors: {
                            "update": action.payload.validationErrors
                        }
                    };
                }
                case OrderActionTypes.DELETE: {
                    return {
                        ...state,
                        loading: {
                            "delete": false
                        },
                        validationErrors: {
                            "delete": action.payload.validationErrors
                        }
                    };
                }
                case OrderActionTypes.RATES: {
                    return {
                        ...state,
                        loading: {
                            "rates": false
                        },
                        validationErrors: {
                            "rates": action.payload.validationErrors
                        }
                    };
                }
                case OrderActionTypes.PREVIEW_PAYMENT_DETAIL: {
                    return {
                        ...state,
                        loading: {
                            "previewPaymentDetail": false
                        },
                        validationErrors: {
                            "previewPaymentDetail": action.payload.validationErrors
                        }
                    };
                }
                case OrderActionTypes.PAYMENT_STEP_1: {
                    return {
                        ...state,
                        loading: {
                            "paymentStep1": false
                        },
                        validationErrors: {
                            "paymentStep1": action.payload.validationErrors
                        }
                    };
                }
                case OrderActionTypes.PAYMENT_STEP_2: {
                    return {
                        ...state,
                        loading: {
                            "paymentStep2": false
                        },
                        validationErrors: {
                            "paymentStep2": action.payload.validationErrors
                        }
                    };
                }
                case OrderActionTypes.CREATE_BILL: {
                    return {
                        ...state,
                        loading: {
                            "createBill": false
                        },
                        validationErrors: {
                            "createBill": action.payload.validationErrors
                        }
                    };
                }
                case OrderActionTypes.UPDATE_BILL: {
                    return {
                        ...state,
                        loading: {
                            "updateBill": false
                        },
                        validationErrors: {
                            "updateBill": action.payload.validationErrors
                        }
                    };
                }
                case OrderActionTypes.ORDER_BILLS: {
                    return {
                        ...state,
                        loading: {
                            "orderBills": false
                        },
                        validationErrors: {
                            "orderBills": action.payload.validationErrors
                        }
                    };
                }
                case OrderActionTypes.ORDER_BILL: {
                    return {
                        ...state,
                        loading: {
                            "orderBill": false
                        },
                        validationErrors: {
                            "orderBill": action.payload.validationErrors
                        }
                    };
                }
                case OrderActionTypes.CREATE_NOTE: {
                    return {
                        ...state,
                        loading: {
                            "createNote": false
                        },
                        validationErrors: {
                            "createNote": action.payload.validationErrors
                        }
                    };
                }
                case OrderActionTypes.SEARCH: {
                    return {
                        ...state,
                        loading: {
                            "search": false
                        },
                        validationErrors: {
                            "search": action.payload.validationErrors
                        }
                    };
                }
                case OrderActionTypes.EXPORT: {
                    return {
                        ...state,
                        loading: {
                            "export": false
                        },
                        validationErrors: {
                            "export": action.payload.validationErrors
                        }
                    };
                }
                case OrderActionTypes.GET_OPTIONS: {
                    return {
                        ...state,
                        loading: {
                            "getOption": false
                        },
                        validationErrors: {
                            "getOption": action.payload.validationErrors
                        },
                    };
                }
                default:
                    return { ...state };
            }

        case OrderActionTypes.ORDERS:
            return {
                ...state,
                loading: {
                    "orders": true
                },
                error: {
                    "orders": ""
                }
            };
        case OrderActionTypes.ORDER:
            return {
                ...state,
                loading: {
                    "order": true
                },
                error: {
                    "order": ""
                }
            };

        case OrderActionTypes.CREATE:
            return {
                ...state,
                inserted: false,

                loading: {
                    "create": true
                },
                error: {
                    "create": ""
                }
            };
        case OrderActionTypes.UPDATE:
            return {
                ...state,
                updated: false,

                loading: {
                    "update": true
                },
                error: {
                    "update": ""
                }
            };
        case OrderActionTypes.DELETE:
            return {
                ...state,
                deleted: false,

                loading: {
                    "delete": true
                },
                error: {
                    "delete": ""
                }
            };

        case OrderActionTypes.RESET:
            return {
                ...state,

                loading: {},
                error: {},

                validationErrors: {},

                orders: {},
                order: {},
                inserted: false,
                updated: false,
                deleted: false,

                previewDetail: {},

                rates: [],
                paymentStep1Done: false,
                paymentStep2Done: false,

                billCreated: false,
                billUpdated: false,
                orderBills: [],
                orderBill: {},

                noteCreated: false,

                searchModal: false,
                searchMode: false,

                tmpOrders: {},
                tmpQueryParams: {},

                exportUrl: "",

                option: {}

            };

        case OrderActionTypes.RATES:
            return {
                ...state,

                loading: {
                    "rates": true
                },
                error: {
                    "rates": ""
                }
            };

        case OrderActionTypes.PREVIEW_PAYMENT_DETAIL:
            return {
                ...state,
                previewDetail: {},

                loading: {
                    ...state.loading,
                    "previewDetail": true
                },
                error: {
                    ...state.error,
                    "previewDetail": ""
                }
            };

        case OrderActionTypes.RESET_PREVIEW_DETAIL:
            return {
                ...state,
                previewDetail: {},

                loading: {
                    ...state.loading,
                    "previewDetail": false
                },
                error: {
                    ...state.error,
                    "previewDetail": ""
                }
            };

        case OrderActionTypes.PAYMENT_STEP_1:
            return {
                ...state,
                paymentStep1Done: false,
                loading: {
                    "paymentStep1": true
                },
                error: {
                    "paymentStep1": ""
                }
            };

        case OrderActionTypes.PAYMENT_STEP_2:
            return {
                ...state,
                loading: {
                    "paymentStep2": true
                },
                error: {
                    "paymentStep2": ""
                }
            };

        case OrderActionTypes.CREATE_BILL:
            return {
                ...state,
                loading: {
                    "createBill": true
                },
                error: {
                    "createBill": ""
                }
            };

        case OrderActionTypes.UPDATE_BILL:
            return {
                ...state,
                loading: {
                    "updateBill": true
                },
                error: {
                    "updateBill": ""
                }
            };
        case OrderActionTypes.ORDER_BILLS:
            return {
                ...state,
                loading: {
                    "orderBills": true
                },
                error: {
                    "orderBills": ""
                }
            };
        case OrderActionTypes.ORDER_BILL:
            return {
                ...state,
                loading: {
                    "orderBill": true
                },
                error: {
                    "orderBill": ""
                }
            };
        case OrderActionTypes.CREATE_NOTE:
            return {
                ...state,
                loading: {
                    "createNote": true
                },
                error: {
                    "createNote": ""
                }
            };

        // search actions
        case OrderActionTypes.OPEN_SEARCH:
            return {
                ...state,
                searchModal: true,
            };
        case OrderActionTypes.CLOSE_SEARCH:
            return {
                ...state,
                searchModal: false,
                loading: {
                    ...state.loading,
                    "search": false
                },
                error: {
                    ...state.error,
                    "search": ""
                },
                validationErrors: {
                    ...state.validationErrors,
                    "search": ""
                }
            };
        case OrderActionTypes.SEARCH:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    "search": true
                },
                error: {
                    ...state.error,
                    "search": ""
                },
                validationErrors: {
                    ...state.validationErrors,
                    "search": ""
                }
            };
        case OrderActionTypes.CLEAR_SEARCH:
            return {
                ...state,
                orders: state.tmpOrders,
                queryParams: state.tmpQueryParams,
                tmpOrders: {},
                tmpQueryParams: {},
                searchMode: false,
            };

        case OrderActionTypes.EXPORT:

            return {
                ...state,
                exportUrl: "",

                loading: {
                    ...state.loading,
                    "export": true
                },
                error: {
                    ...state.error,
                    "export": ""
                },
                validationErrors: {
                    ...state.validationErrors,
                    "export": ""
                }
            };

        case OrderActionTypes.RESET_SEARCH:

            return {
                ...state,
                searchModal: false,
                exportUrl: "",

                loading: {
                    ...state.loading,
                    "search": false,
                    "export": false
                },
                error: {
                    ...state.error,
                    "search": "",
                    "export": ""
                },
                validationErrors: {
                    ...state.validationErrors,
                    "search": "",
                    "export": ""
                }
            }

        case OrderActionTypes.GET_OPTIONS:

            return {
                ...state,
                loading: {
                    "getOption": true
                },
                error: {
                    "getOption": ""
                }
            };

        default:
            return { ...state };
    }
};

export default Order;
