import {TransactionActionTypes} from './constants';

export type DWTransactionActionType = {
    type:
        | TransactionActionTypes.API_RESPONSE_SUCCESS
        | TransactionActionTypes.API_RESPONSE_ERROR
        | TransactionActionTypes.API_RESPONSE_VALIDATION_ERRORS
        | TransactionActionTypes.FIND_TRANSACTION
        | TransactionActionTypes.GET_TRANSACTIONS

        | TransactionActionTypes.OPEN_SEARCH
        | TransactionActionTypes.CLOSE_SEARCH
        | TransactionActionTypes.SEARCH_TRANSACTION
        | TransactionActionTypes.CLEAR_SEARCH

        | TransactionActionTypes.RESET
        | TransactionActionTypes.EXPORT
        | TransactionActionTypes.RESET_SEARCH

        | TransactionActionTypes.CHART_DATA;
    payload: {} | string;
};

type Transaction = {
    id: number;
    name: string;
    guard_name: string;
};

type QueryParams = {
    page: number;
    limit: number;
    orderBy: string,
    sort: string,
    TransactionName?: string,
    accountId?: string
}

class DWTransactionAction {

    apiResponseSuccess = (actionType: string, data: Transaction | {}): DWTransactionActionType => ({
        type: TransactionActionTypes.API_RESPONSE_SUCCESS,
        payload: { actionType, data },
    });

    apiResponseError = (actionType: string, error: string): DWTransactionActionType => ({
        type: TransactionActionTypes.API_RESPONSE_ERROR,
        payload: { actionType, error },
    });

    apiResponseValidationErrors = (actionType: string, validationErrors: []): DWTransactionActionType => ({
        type: TransactionActionTypes.API_RESPONSE_VALIDATION_ERRORS,
        payload: { actionType, validationErrors },
    });

    reset = (): DWTransactionActionType => ({
        type: TransactionActionTypes.RESET,
        payload: {},
    });

    getTransactions = (queryParams: QueryParams): DWTransactionActionType => ({
        type: TransactionActionTypes.GET_TRANSACTIONS,
        payload: { queryParams },
    });

    find = (id: number) : DWTransactionActionType => ({
        type: TransactionActionTypes.FIND_TRANSACTION,
        payload: {
            id,
        }
    });

    openSearch = (): DWTransactionActionType => ({
        type: TransactionActionTypes.OPEN_SEARCH,
        payload: {}
    });

    closeSearch = (): DWTransactionActionType => ({
        type: TransactionActionTypes.CLOSE_SEARCH,
        payload: {}
    });

    search = (queryParams: QueryParams): DWTransactionActionType => ({
        type: TransactionActionTypes.SEARCH_TRANSACTION,
        payload: { queryParams }
    });

    clearSearch = (): DWTransactionActionType => ({
        type: TransactionActionTypes.CLEAR_SEARCH,
        payload: {}
    });

    export = (queryParams: QueryParams): DWTransactionActionType => ({
        type: TransactionActionTypes.EXPORT,
        payload: { queryParams }
    });

    resetSearch = (): DWTransactionActionType => ({
        type: TransactionActionTypes.RESET_SEARCH,
        payload: {}
    });

    getChartData = (params: { interval: string }): DWTransactionActionType => ({
        type: TransactionActionTypes.CHART_DATA,
        payload: { ...params }
    });
}

const DWTransactionActions = new DWTransactionAction();
export { DWTransactionActions };
