import {OfflineDepositSettingActionTypes} from './constants';

export type OfflineDepositSettingActionType = {
    type:
        | OfflineDepositSettingActionTypes.API_RESPONSE_SUCCESS
        | OfflineDepositSettingActionTypes.API_RESPONSE_ERROR
        | OfflineDepositSettingActionTypes.API_RESPONSE_VALIDATION_ERRORS
        | OfflineDepositSettingActionTypes.RESET
        | OfflineDepositSettingActionTypes.GET_OPTIONS
        | OfflineDepositSettingActionTypes.UPDATE_SETTING;

    payload: {} | string;
};

class OfflineDepositSettingActions {

    apiResponseSuccess = (actionType: string, data: any): OfflineDepositSettingActionType => ({
        type: OfflineDepositSettingActionTypes.API_RESPONSE_SUCCESS,
        payload: { actionType, data },
    });

    apiResponseError = (actionType: string, error: string, trackId: string = ""): OfflineDepositSettingActionType => ({
        type: OfflineDepositSettingActionTypes.API_RESPONSE_ERROR,
        payload: { actionType, error, trackId },
    });

    apiResponseValidationErrors = (actionType: string, validationErrors: [], trackId: string = ""): OfflineDepositSettingActionType => ({
        type: OfflineDepositSettingActionTypes.API_RESPONSE_VALIDATION_ERRORS,
        payload: { actionType, validationErrors, trackId },
    });

    reset = (): OfflineDepositSettingActionType => ({
        type: OfflineDepositSettingActionTypes.RESET,
        payload: {},
    });

    updateSetting = (data: any, trackId: string): OfflineDepositSettingActionType => ({
        type: OfflineDepositSettingActionTypes.UPDATE_SETTING,
        payload: { data, trackId },
    });

    getOptions = (data: any): OfflineDepositSettingActionType => ({
        type: OfflineDepositSettingActionTypes.GET_OPTIONS,
        payload: { data },
    });
}

const offlineDepositSettingActions = new OfflineDepositSettingActions();
export { offlineDepositSettingActions };
