import { TransactionActionTypes } from './constants';
import {isEmptyObject} from "../../helpers/functions";

const INIT_STATE = {
    transaction: {},

    transactions: {},
    queryParams: {},

    error: {},
    validationErrors: {},
    loading: {},

    searchModal: false,
    tmpTransactions: {},
    tmpQueryParams: {},
    searchMode: false,

    exportUrl: "",

    chartData: []
};

type TransactionData = {
    id: number;
    name: string;
    guard_name: string;
};

type TransactionQueryParams = {
    limit: number;
    page: number;
    orderBy: string;
    sort: string;
    transactionNames: string;
};

type TransactionsResult = {
    transactions: TransactionData | {},
    queryParams: TransactionQueryParams
}

type TransactionActionType = {
    type:
        | TransactionActionTypes.API_RESPONSE_SUCCESS
        | TransactionActionTypes.API_RESPONSE_ERROR
        | TransactionActionTypes.API_RESPONSE_VALIDATION_ERRORS
        | TransactionActionTypes.GET_TRANSACTIONS
        | TransactionActionTypes.GET_TRANSACTION
        | TransactionActionTypes.RESET
        | TransactionActionTypes.EXPORT
        | TransactionActionTypes.RESET_SEARCH

        | TransactionActionTypes.OPEN_SEARCH
        | TransactionActionTypes.CLOSE_SEARCH
        | TransactionActionTypes.SEARCH_TRANSACTION
        | TransactionActionTypes.CLEAR_SEARCH

        | TransactionActionTypes.CHART_DATA;

    payload: {
        actionType?: string;
        data?: TransactionsResult | any;
        error?: string;
        validationErrors?: [];
    };
};

type State = {
    transactions?: TransactionData | {};
    queryParams?: {};

    loading?: {};
    error?: {};
    validationErrors?: {};
    value?: boolean;
    tmpTransactions?: any;
    tmpQueryParams?: any;
};

const Transaction = (state: State = INIT_STATE, action: TransactionActionType) => {
    switch (action.type) {
        case TransactionActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case TransactionActionTypes.GET_TRANSACTIONS: {
                    return {
                        ...state,
                        transactions: action.payload.data?.transactions,
                        queryParams: action.payload.data?.queryParams,
                        loading: {
                            transactions: false
                        },
                    };
                }
                case TransactionActionTypes.GET_TRANSACTION: {
                    return {
                        ...state,
                        transaction: action.payload.data,
                        loading: {
                            transaction: false
                        },
                    };
                }
                case TransactionActionTypes.SEARCH_TRANSACTION: {
                    return {
                        ...state,
                        // this condition used for prevent multiple search
                        tmpTransactions: isEmptyObject(state.tmpTransactions)? state.transactions: state.tmpTransactions,
                        tmpQueryParams: isEmptyObject(state.tmpQueryParams)? state.queryParams: state.tmpQueryParams,

                        transactions: action.payload.data?.transactions,
                        queryParams: action.payload.data?.queryParams,

                        searchMode: true,
                        searchModal: false,

                        loading: {
                            search: false
                        },
                    };
                }
                case TransactionActionTypes.EXPORT: {
                    return {
                        ...state,
                        exportUrl: action.payload.data,
                        loading: {
                            export: false,
                        },

                        searchMode: false,
                        searchModal: false,
                    };
                }
                case TransactionActionTypes.CHART_DATA: {
                    return {
                        ...state,
                        chartData: action.payload.data,
                        loading: {
                            chart: false
                        },
                    };
                }
                default:
                    return { ...state };
            }

        case TransactionActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case TransactionActionTypes.GET_TRANSACTION: {
                    return {
                        ...state,
                        error: {
                            transaction: action.payload.error
                        },
                        loading: {
                            transaction: false
                        },
                    };
                }
                case TransactionActionTypes.GET_TRANSACTIONS: {
                    return {
                        ...state,
                        error: {
                            transactions: action.payload.error
                        },
                        loading: {
                            transactions: false
                        },
                    };
                }
                case TransactionActionTypes.SEARCH_TRANSACTION: {
                    return {
                        ...state,
                        error: {
                            search: action.payload.error
                        },
                        loading: {
                            search: false
                        },
                    };
                }
                case TransactionActionTypes.EXPORT: {
                    return {
                        ...state,
                        error: {
                            export: action.payload.error
                        },
                        loading: {
                            export: false
                        },
                    };
                }
                case TransactionActionTypes.CHART_DATA: {
                    return {
                        ...state,
                        error: {
                            chart: action.payload.error
                        },
                        loading: {
                            chart: false
                        },
                    };
                }
                default:
                    return { ...state };
            }

        case TransactionActionTypes.API_RESPONSE_VALIDATION_ERRORS:

            switch (action.payload.actionType) {
                case TransactionActionTypes.GET_TRANSACTIONS: {
                    return {
                        ...state,
                        error: {
                            transactions: "Invalid input error"
                        },
                        loading: {
                            transactions: false
                        },
                    };
                }
                case TransactionActionTypes.GET_TRANSACTION: {
                    return {
                        ...state,
                        validationErrors: {
                            transaction: action.payload.validationErrors
                        },
                        loading: {
                            transaction: false
                        },
                    };
                }
                case TransactionActionTypes.SEARCH_TRANSACTION: {
                    return {
                        ...state,
                        validationErrors: {
                            search: action.payload.validationErrors
                        },
                        loading: {
                            search: false
                        },
                    };
                }
                case TransactionActionTypes.EXPORT: {
                    return {
                        ...state,
                        validationErrors: {
                            export: action.payload.validationErrors
                        },
                        loading: {
                            export: false
                        },
                    };
                }
                case TransactionActionTypes.CHART_DATA: {
                    return {
                        ...state,
                        validationErrors: {
                            chart: action.payload.validationErrors
                        },
                        loading: {
                            chart: false
                        },
                    };
                }
                default:
                    return { ...state };
            }

        case TransactionActionTypes.GET_TRANSACTIONS:
            return {
                ...state,
                // transactions: {},
                error: {
                    transactions: ""
                },
                loading: {
                    transactions: true
                },
            };
        case TransactionActionTypes.GET_TRANSACTION:
            return {
                ...state,
                transaction: {},
                error: {
                    transaction: ""
                },
                loading: {
                    transaction: true
                },
            };

        case TransactionActionTypes.RESET:
            return {
                ...state,
                transaction: {},

                transactions: {},
                queryParams: {},

                loading: {},
                error: {},
                validationErrors: {},

                searchModal: false,
                searchMode: false,

                tmpTransactions: {},
                tmpQueryParams: {},

                exportUrl: "",

                chartData: []
            };


        // search actions
        case TransactionActionTypes.OPEN_SEARCH:
            return {
                ...state,
                searchModal: true,
            };
        case TransactionActionTypes.CLOSE_SEARCH:
            return {
                ...state,
                searchModal: false,
                loading: {
                    ...state.loading,
                    search: true,
                },
                error: {
                    ...state.error,
                    search: "",
                },
                validationErrors: {
                    ...state.validationErrors,
                    search: "",
                },
            };
        case TransactionActionTypes.SEARCH_TRANSACTION:
            return {
                ...state,

                loading: {
                    ...state.loading,
                    search: true,
                },
                error: {
                    ...state.error,
                    search: "",
                },
                validationErrors: {
                    ...state.validationErrors,
                    search: "",
                },
            };

        case TransactionActionTypes.CLEAR_SEARCH:
            return {
                ...state,
                transactions: state.tmpTransactions,
                queryParams: state.tmpQueryParams,
                tmpTransactions: {},
                tmpQueryParams: {},
                searchMode: false,
            };

        case TransactionActionTypes.EXPORT:

            return {
                ...state,
                exportUrl: "",

                loading: {
                    ...state.loading,
                    export: true,
                },
                error: {
                    ...state.error,
                    export: "",
                },
                validationErrors: {
                    ...state.validationErrors,
                    export: "",
                },
            };

        case TransactionActionTypes.RESET_SEARCH:

            return {
                ...state,
                searchModal: false,

                exportUrl: "",

                loading: {
                    ...state.loading,
                    search: false,
                    export: false,
                },
                error: {
                    ...state.error,
                    search: "",
                    export: "",
                },
                validationErrors: {
                    ...state.validationErrors,
                    search: {},
                    export: {},
                },
            }

        case TransactionActionTypes.CHART_DATA: {
            return {
                ...state,
                error: {
                    chart: ""
                },
                loading: {
                    chart: false
                },
            };
        }

        default:
            return { ...state };
    }
};

export default Transaction;
