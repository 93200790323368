export enum TransactionActionTypes {
    API_RESPONSE_SUCCESS = '@@DWTransaction/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@DWTransaction/API_RESPONSE_ERROR',
    API_RESPONSE_VALIDATION_ERRORS = '@@DWTransaction/API_RESPONSE_VALIDATION_ERRORS',

    FIND_TRANSACTION = '@@DWTransaction/FIND_TRANSACTION',
    GET_TRANSACTIONS = '@@DWTransaction/GET_TRANSACTIONS',

    OPEN_SEARCH = '@@DWTransaction/OPEN_SEARCH',
    CLOSE_SEARCH = '@@DWTransaction/CLOSE_SEARCH',
    SEARCH_TRANSACTION = '@@DWTransaction/SEARCH_TRANSACTION',
    CLEAR_SEARCH = '@@DWTransaction/CLEAR_SEARCH',

    RESET = '@@DWTransaction/RESET',

    EXPORT = '@@DWTransaction/EXPORT',
    RESET_SEARCH = '@@DWTransaction/RESET_SEARCH',

    CHART_DATA = '@@DWTransaction/CHART_DATA',
}

export enum TransactionErrors {
    RESPONSE_200 = 'Unfortunately, an error has occurred. Please try again.',
}



