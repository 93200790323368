import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {CashIncomeActionTypes, CashIncomeErrors} from "./constants";
import {cashIncomeActions} from "./actions";
import {graphql} from "../../helpers";
import {SagaIterator} from "@redux-saga/core";
import {checkResponseError, checkServerError, pluckResponse} from "../../helpers/functions";

type CashIncomeData = {
    payload: {
        id: string;

        limit: string;
        page: string;

        queryParams: {
            limit: string;
            page: string;
            cashIncomeBy: string;
            sort: string;
        };

        data: any;
        trackId: string;
        hashedId: string;
    };
    type: string;
};

function* order({ payload: { queryParams } }: CashIncomeData): SagaIterator {
    try {
        const param = {
            query:`query OrderCashIncome($hashedId: String!) {
                orderCashIncome(hashedId: $hashedId) {
                    hashedId
                    user {
                        id
                        username
                    }
                    created_at
                    updated_at
                    amount
                    currency
                    url
                    data {
                        username
                        password
                    }
                    rate
                    rate_currency
                    fee
                    fee_currency
                    discount
                    discount_currency
                    name
                    final_amount
                    final_amount_currency
                    description
                    status
                    bills {
                        hashedId
                        amount
                        currency
                        status
                        created_at
                    }
                    notes {
                        note
                        created_at
                    }
                    transactions {
                        transaction {
                            transaction_id
                            thread_id
                            type
                            status
                            name
                            amountFloat
                            wallet {
                                name
                            }
                            created_at
                        }
                    }
                }
            }`,

            variables: {
                ...queryParams
            },
            operationName: "OrderCashIncome"
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, CashIncomeErrors.RESPONSE_200);

        const rCashIncome = response.data;

        checkServerError(rCashIncome);

        const order = pluckResponse(rCashIncome, "orderCashIncome");

        yield put(cashIncomeActions.apiResponseSuccess(CashIncomeActionTypes.ORDER, order));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(cashIncomeActions.apiResponseError(CashIncomeActionTypes.ORDER, error));
        } else {
            yield put(cashIncomeActions.apiResponseValidationErrors(CashIncomeActionTypes.ORDER, error));
        }
    }
}

function* create({ payload: {data} }: CashIncomeData): SagaIterator {

    try {
        const param = {

            query:`mutation CreateCashIncome(
                $amount: String!,
                $currency: String!,
                $acceptConditions: String!,
                $descriptions: String,
            ) {
                createCashIncome(
                    amount: $amount,
                    currency: $currency,
                    acceptConditions: $acceptConditions,
                    descriptions: $descriptions,
                )
            }`,

            variables: { ...data },
            operationName: "CreateCashIncome"
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, CashIncomeErrors.RESPONSE_200);

        const rCashIncome = response.data;

        checkServerError(rCashIncome);

        const createCashIncome = pluckResponse(rCashIncome, "createCashIncome");

        yield put(cashIncomeActions.apiResponseSuccess(CashIncomeActionTypes.CREATE, createCashIncome));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(cashIncomeActions.apiResponseError(CashIncomeActionTypes.CREATE, error));
        } else {
            yield put(cashIncomeActions.apiResponseValidationErrors(CashIncomeActionTypes.CREATE, error));
        }
    }
}

function* update({ payload: { data } }: CashIncomeData): SagaIterator {
    try {
        const param = {

            query:`mutation UpdateCashIncome(
                $hashedId: String!,
                $amount: String!,
                $currency: String!,
                $descriptions: String,
                $status: String!,
                $note: String,
                $updateRate: Boolean,
            ) {
                updateCashIncome(
                    hashedId: $hashedId,
                    amount: $amount,
                    currency: $currency,
                    descriptions: $descriptions,
                    status: $status,
                    note: $note,
                    updateRate: $updateRate,
                )
            }`,

            variables: { ...data },
            operationName: "UpdateCashIncome"
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, CashIncomeErrors.RESPONSE_200);

        const rCashIncome = response.data;

        checkServerError(rCashIncome);

        pluckResponse(rCashIncome, "updateCashIncome");

        yield put(cashIncomeActions.apiResponseSuccess(CashIncomeActionTypes.UPDATE, true));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(cashIncomeActions.apiResponseError(CashIncomeActionTypes.UPDATE, error));
        } else {
            yield put(cashIncomeActions.apiResponseValidationErrors(CashIncomeActionTypes.UPDATE, error));
        }
    }
}

function* cancel({ payload: {hashedId} }: CashIncomeData): SagaIterator {
    try {
        const param = {

            query:`mutation CancelCashIncome(
                $hashedId: String!
            ) {
                cancelCashIncome(
                    hashedId: $hashedId
                )
            }`,

            variables: {
                hashedId
            },
            operationName: "CancelCashIncome"
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, CashIncomeErrors.RESPONSE_200);

        const rCashIncome = response.data;

        checkServerError(rCashIncome);

        pluckResponse(rCashIncome, "cancelCashIncome");

        yield put(cashIncomeActions.apiResponseSuccess(CashIncomeActionTypes.CANCEL, hashedId));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(cashIncomeActions.apiResponseError(CashIncomeActionTypes.CANCEL, error));
        } else {
            yield put(cashIncomeActions.apiResponseValidationErrors(CashIncomeActionTypes.CANCEL, error));
        }
    }
}

function* review({ payload: { data } }: CashIncomeData): SagaIterator {
    try {
        const param = {
            query:`query CashIncomeReview($amount: String!, $currency: String!, $rate: String) {
                cashIncomeReview(amount: $amount, currency: $currency, rate: $rate) {
                    amount
                    amount_currency
                    rate
                    rate_currency
                    fee
                    fee_currency
                    final_amount
                    final_amount_currency
                }
            }`,

            variables: { ...data },
            operationName: "CashIncomeReview"
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, CashIncomeErrors.RESPONSE_200);

        const rOPR = response.data;

        checkServerError(rOPR);

        const cashIncomeReview = pluckResponse(rOPR, "cashIncomeReview");

        yield put(cashIncomeActions.apiResponseSuccess(CashIncomeActionTypes.REVIEW, cashIncomeReview));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(cashIncomeActions.apiResponseError(CashIncomeActionTypes.REVIEW, error));
        } else {
            yield put(cashIncomeActions.apiResponseValidationErrors(CashIncomeActionTypes.REVIEW, error));
        }
    }
}

function* updateCaSetting({ payload: { data, trackId } }: CashIncomeData): SagaIterator {

    try {

        const paymentMethods = data.paymentMethods || [];

        // Transform the array of objects into separate arrays for each field
        const paymentMethod = paymentMethods.map((item: any) => item.paymentMethod);
        const currencyCat = paymentMethods.map((item: any) => item.currencyCat);
        const cashIncomeFee = paymentMethods.map((item: any) => item.cashIncomeFee);


        const param = {
            query:`mutation UpdateCashIncomeSetting(
                $paymentMethod: [String!]
                $currencyCat: [String!]
                $cashIncomeFee: [Float!]
                $cashIncomeStatus: String!
            ) {
                updateCashIncomeSetting(
                    paymentMethod: $paymentMethod
                    currencyCat: $currencyCat
                    cashIncomeFee: $cashIncomeFee
                    cashIncomeStatus: $cashIncomeStatus
                )
            }`,

            variables: {
                paymentMethod,
                currencyCat,
                cashIncomeFee,
                cashIncomeStatus: data.cashIncomeStatus,
            },

            operationName: "UpdateCashIncomeSetting"
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, CashIncomeErrors.RESPONSE_200);

        const responseCR = response.data;

        checkServerError(responseCR);

        pluckResponse(responseCR, "updateCashIncomeSetting");

        yield put(cashIncomeActions.apiResponseSuccess(CashIncomeActionTypes.UPDATE_SETTING, {
            result: true,
            trackId: trackId
        }));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(cashIncomeActions.apiResponseError(CashIncomeActionTypes.UPDATE_SETTING, error, trackId));
        } else {
            yield put(cashIncomeActions.apiResponseValidationErrors(CashIncomeActionTypes.UPDATE_SETTING, error, trackId));
        }
    }
}

function* getCaSetting(): SagaIterator {

    try {
        const param = {
            query:`query CashIncomeSetting {
                cashIncomeSetting {
                    cash_income_status
                    cash_income_payment_methods {
                        paymentMethod
                        currencyCat
                        cashIncomeFee
                    }
                }
            }`,

            operationName: "CashIncomeSetting"
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, CashIncomeErrors.RESPONSE_200);

        const responseCR = response.data;

        checkServerError(responseCR);

        const getCaSetting = pluckResponse(responseCR, "cashIncomeSetting");

        yield put(cashIncomeActions.apiResponseSuccess(CashIncomeActionTypes.GET_SETTING, getCaSetting));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(cashIncomeActions.apiResponseError(CashIncomeActionTypes.GET_SETTING, error));
        } else {
            yield put(cashIncomeActions.apiResponseValidationErrors(CashIncomeActionTypes.GET_SETTING, error));
        }
    }
}

export function* watchOrder() {
    yield takeEvery(CashIncomeActionTypes.ORDER, order);
}

export function* watchInsert() {
    yield takeEvery(CashIncomeActionTypes.CREATE, create);
}

export function* watchUpdate() {
    yield takeEvery(CashIncomeActionTypes.UPDATE, update);
}

export function* watchCancel() {
    yield takeEvery(CashIncomeActionTypes.CANCEL, cancel);
}

export function* watchReview() {
    yield takeEvery(CashIncomeActionTypes.REVIEW, review);
}

export function* watchUpdateCaSetting() {
    yield takeEvery(CashIncomeActionTypes.UPDATE_SETTING, updateCaSetting);
}

export function* watchGetCaSetting() {
    yield takeEvery(CashIncomeActionTypes.GET_SETTING, getCaSetting);
}

function* cashIncomeSaga() {
    yield all([
        fork(watchOrder),
        fork(watchInsert),
        fork(watchUpdate),
        fork(watchCancel),
        fork(watchReview),
        fork(watchUpdateCaSetting),
        fork(watchGetCaSetting),
    ]);
}

export default cashIncomeSaga;
