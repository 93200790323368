export enum OfflineDepositSettingActionTypes {
    API_RESPONSE_SUCCESS = '@@offlineDepositSetting/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@offlineDepositSetting/API_RESPONSE_ERROR',
    API_RESPONSE_VALIDATION_ERRORS = '@@offlineDepositSetting/API_RESPONSE_VALIDATION_ERRORS',

    RESET = '@@offlineDepositSetting/RESET',

    GET_OPTIONS = '@@offlineDepositSetting/GET_OPTIONS',
    UPDATE_SETTING = '@@offlineDepositSetting/UPDATE_SETTING',
}

export enum OfflineDepositSettingErrors {
    RESPONSE_200 = 'Unfortunately, an error has occurred. Please try again.'
}



