export enum OfflineDepositActionTypes {
    API_RESPONSE_SUCCESS = '@@offlineDeposit/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@offlineDeposit/API_RESPONSE_ERROR',
    API_RESPONSE_VALIDATION_ERRORS = '@@offlineDeposit/API_RESPONSE_VALIDATION_ERRORS',

    FIND_DEPOSIT = '@@offlineDeposit/FIND_DEPOSIT',
    GET_DEPOSITS = '@@offlineDeposit/GET_DEPOSITS',
    GET_DEPOSIT = '@@offlineDeposit/GET_DEPOSIT',
    INSERT_DEPOSIT = '@@offlineDeposit/INSERT_DEPOSIT',

    OPEN_SEARCH = '@@offlineDeposit/OPEN_SEARCH',
    CLOSE_SEARCH = '@@offlineDeposit/CLOSE_SEARCH',
    SEARCH_DEPOSIT = '@@offlineDeposit/SEARCH_DEPOSIT',
    CLEAR_SEARCH = '@@offlineDeposit/CLEAR_SEARCH',

    RESET = '@@offlineDeposit/RESET',

    EXPORT = '@@offlineDeposit/EXPORT',
    RESET_SEARCH = '@@offlineDeposit/RESET_SEARCH',

    GET_OPTIONS = '@@offlineDeposit/GET_OPTIONS',

    APPROVE = '@@offlineDeposit/APPROVE',
    RESET_VALIDATION_ERROR = '@@offlineDeposit/RESET_VALIDATION_ERROR',
}

export enum OfflineDepositErrors {
    RESPONSE_200 = 'Unfortunately, an error has occurred. Please try again.',
}



