import {OfflineDepositActionTypes} from './constants';

export type OfflineDepositActionType = {
    type:
        | OfflineDepositActionTypes.API_RESPONSE_SUCCESS
        | OfflineDepositActionTypes.API_RESPONSE_ERROR
        | OfflineDepositActionTypes.API_RESPONSE_VALIDATION_ERRORS
        | OfflineDepositActionTypes.FIND_DEPOSIT
        | OfflineDepositActionTypes.GET_DEPOSITS
        | OfflineDepositActionTypes.GET_DEPOSIT
        | OfflineDepositActionTypes.INSERT_DEPOSIT

        | OfflineDepositActionTypes.OPEN_SEARCH
        | OfflineDepositActionTypes.CLOSE_SEARCH
        | OfflineDepositActionTypes.SEARCH_DEPOSIT
        | OfflineDepositActionTypes.CLEAR_SEARCH
        | OfflineDepositActionTypes.RESET_SEARCH
        | OfflineDepositActionTypes.GET_OPTIONS

        | OfflineDepositActionTypes.EXPORT

        | OfflineDepositActionTypes.RESET

        | OfflineDepositActionTypes.APPROVE
        | OfflineDepositActionTypes.RESET_VALIDATION_ERROR;

    payload: {} | string;
};

type Deposit = {
    id: number;
    name: string;
    guard_name: string;
};

type QueryParams = {
    page: number;
    limit: number;
    orderBy: string,
    sort: string,
    depositName?: string
}

class OfflineDepositActions {

    apiResponseSuccess = (actionType: string, data: Deposit | {}): OfflineDepositActionType => ({
        type: OfflineDepositActionTypes.API_RESPONSE_SUCCESS,
        payload: { actionType, data },
    });

    apiResponseError = (actionType: string, error: string): OfflineDepositActionType => ({
        type: OfflineDepositActionTypes.API_RESPONSE_ERROR,
        payload: { actionType, error },
    });

    apiResponseValidationErrors = (actionType: string, validationErrors: []): OfflineDepositActionType => ({
        type: OfflineDepositActionTypes.API_RESPONSE_VALIDATION_ERRORS,
        payload: { actionType, validationErrors },
    });

    reset = (): OfflineDepositActionType => ({
        type: OfflineDepositActionTypes.RESET,
        payload: {},
    });

    getDeposits = (queryParams: QueryParams): OfflineDepositActionType => ({
        type: OfflineDepositActionTypes.GET_DEPOSITS,
        payload: { queryParams },
    });

    getDeposit = (id: string): OfflineDepositActionType => ({
        type: OfflineDepositActionTypes.GET_DEPOSIT,
        payload: { id },
    });

    insert = (data: any): OfflineDepositActionType => ({
        type: OfflineDepositActionTypes.INSERT_DEPOSIT,
        payload: { data }
    });

    find = (id: number) : OfflineDepositActionType => ({
        type: OfflineDepositActionTypes.FIND_DEPOSIT,
        payload: {
            id,
        }
    });

    openSearch = (): OfflineDepositActionType => ({
        type: OfflineDepositActionTypes.OPEN_SEARCH,
        payload: {}
    });

    closeSearch = (): OfflineDepositActionType => ({
        type: OfflineDepositActionTypes.CLOSE_SEARCH,
        payload: {}
    });

    search = (queryParams: QueryParams): OfflineDepositActionType => ({
        type: OfflineDepositActionTypes.SEARCH_DEPOSIT,
        payload: { queryParams }
    });

    clearSearch = (): OfflineDepositActionType => ({
        type: OfflineDepositActionTypes.CLEAR_SEARCH,
        payload: {}
    });

    export = (queryParams: QueryParams): OfflineDepositActionType => ({
        type: OfflineDepositActionTypes.EXPORT,
        payload: { queryParams }
    });

    resetSearch = (): OfflineDepositActionType => ({
        type: OfflineDepositActionTypes.RESET_SEARCH,
        payload: {}
    });

    getOptions = (data: any): OfflineDepositActionType => ({
        type: OfflineDepositActionTypes.GET_OPTIONS,
        payload: { data },
    });

    approve = (data: any): OfflineDepositActionType => ({
        type: OfflineDepositActionTypes.APPROVE,
        payload: { data },
    });

    resetValidationError = (): OfflineDepositActionType => ({
        type: OfflineDepositActionTypes.RESET_VALIDATION_ERROR,
        payload: { },
    });
}

const offlineDepositActions = new OfflineDepositActions();
export { offlineDepositActions };
