export enum ExportRequestActionTypes {
    API_RESPONSE_SUCCESS = '@@exportRequest/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@exportRequest/API_RESPONSE_ERROR',
    API_RESPONSE_VALIDATION_ERRORS = '@@exportRequest/API_RESPONSE_VALIDATION_ERRORS',

    GET_LOGS = '@@exportRequest/GET_LOGS',
    GET_LOG = '@@exportRequest/GET_LOG',

    OPEN_SEARCH = '@@exportRequest/OPEN_SEARCH',
    CLOSE_SEARCH = '@@exportRequest/CLOSE_SEARCH',
    SEARCH_LOG = '@@exportRequest/SEARCH_LOG',
    SEARCH_TRANSACTION = '@@exportRequest/SEARCH_TRANSACTION',
    CLEAR_SEARCH = '@@exportRequest/CLEAR_SEARCH',

    RESET = '@@exportRequest/RESET',
}

export enum ExportRequestErrors {
    RESPONSE_200 = 'Unfortunately, an error has occurred. Please try again.',
}



