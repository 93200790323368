import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {IntlMoneyTransferActionTypes, IntlMoneyTransferErrors} from "./constants";
import {intlMoneyTransferActions} from "./actions";
import {graphql} from "../../helpers";
import {SagaIterator} from "@redux-saga/core";
import {checkResponseError, checkServerError, pluckResponse} from "../../helpers/functions";

type IntlMoneyTransferData = {
    payload: {
        id: string;

        limit: string;
        page: string;

        queryParams: {
            limit: string;
            page: string;
            intlMoneyTransferBy: string;
            sort: string;
        };

        data: any;
        trackId: string;
        hashedId: string;
    };
    type: string;
};

function* order({ payload: { queryParams } }: IntlMoneyTransferData): SagaIterator {
    try {
        const param = {
            query:`query OrderIntlMoneyTransfer($hashedId: String!) {
                orderIntlMoneyTransfer(hashedId: $hashedId) {
                    hashedId
                    user {
                        id
                        username
                    }
                    created_at
                    updated_at
                    amount
                    currency
                    url
                    data {
                        wsName
                        ppEmail
                        wsEmail
                        pmAccountNumber
                    }
                    rate
                    rate_currency
                    fee
                    fee_currency
                    discount
                    discount_currency
                    name
                    final_amount
                    final_amount_currency
                    description
                    status
                    bills {
                        hashedId
                        amount
                        currency
                        status
                        created_at
                    }
                    notes {
                        note
                        created_at
                    }
                    transactions {
                        transaction {
                            transaction_id
                            thread_id
                            type
                            status
                            name
                            amountFloat
                            wallet {
                                name
                            }
                            created_at
                        }
                    }
                }
            }`,

            variables: {
                ...queryParams
            },
            operationName: "OrderIntlMoneyTransfer"
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, IntlMoneyTransferErrors.RESPONSE_200);

        const rIntlMoneyTransfer = response.data;

        checkServerError(rIntlMoneyTransfer);

        const order = pluckResponse(rIntlMoneyTransfer, "orderIntlMoneyTransfer");

        yield put(intlMoneyTransferActions.apiResponseSuccess(IntlMoneyTransferActionTypes.ORDER, order));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(intlMoneyTransferActions.apiResponseError(IntlMoneyTransferActionTypes.ORDER, error));
        } else {
            yield put(intlMoneyTransferActions.apiResponseValidationErrors(IntlMoneyTransferActionTypes.ORDER, error));
        }
    }
}

function* create({ payload: {data} }: IntlMoneyTransferData): SagaIterator {

    try {
        const param = {
            query: `
    mutation CreateIntlMoneyTransfer(
      $amount: String!,
      $currency: String!,
      $ppEmail: String,
      $pmAccountNumber: String,
      $wsName: String,
      $wsEmail: String,
      $descriptions: String,
      $acceptConditions: String!
    ) {
      createIntlMoneyTransfer(
        amount: $amount
        currency: $currency
        ppEmail: $ppEmail
        pmAccountNumber: $pmAccountNumber
        wsName: $wsName
        wsEmail: $wsEmail
        descriptions: $descriptions
        acceptConditions: $acceptConditions
      )
    }
  `,
            variables: {
                ...data
            },
            operationName: "CreateIntlMoneyTransfer"
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, IntlMoneyTransferErrors.RESPONSE_200);

        const rIntlMoneyTransfer = response.data;

        checkServerError(rIntlMoneyTransfer);

        const createIntlMoneyTransfer = pluckResponse(rIntlMoneyTransfer, "createIntlMoneyTransfer");

        yield put(intlMoneyTransferActions.apiResponseSuccess(IntlMoneyTransferActionTypes.CREATE, createIntlMoneyTransfer));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(intlMoneyTransferActions.apiResponseError(IntlMoneyTransferActionTypes.CREATE, error));
        } else {
            yield put(intlMoneyTransferActions.apiResponseValidationErrors(IntlMoneyTransferActionTypes.CREATE, error));
        }
    }
}

function* update({ payload: {data} }: IntlMoneyTransferData): SagaIterator {
    try {
        const param = {

            query:`mutation UpdateIntlMoneyTransfer(
                $hashedId: String!,
                $amount: String!,
                $currency: String!,
                $ppEmail: String,
                $pmAccountNumber: String,
                $wsName: String,
                $wsEmail: String,
                $descriptions: String,
                $status: String!,
                $note: String,
                $updateRate: Boolean,
            ) {
                updateIntlMoneyTransfer(
                    hashedId: $hashedId,
                    amount: $amount,
                    currency: $currency,
                    ppEmail: $ppEmail
                    pmAccountNumber: $pmAccountNumber
                    wsName: $wsName
                    wsEmail: $wsEmail
                    descriptions: $descriptions,
                    status: $status,
                    note: $note,
                    updateRate: $updateRate,
                )
            }`,

            variables: { ...data },
            operationName: "UpdateIntlMoneyTransfer"
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, IntlMoneyTransferErrors.RESPONSE_200);

        const rIntlMoneyTransfer = response.data;

        checkServerError(rIntlMoneyTransfer);

        pluckResponse(rIntlMoneyTransfer, "updateIntlMoneyTransfer");

        yield put(intlMoneyTransferActions.apiResponseSuccess(IntlMoneyTransferActionTypes.UPDATE, true));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(intlMoneyTransferActions.apiResponseError(IntlMoneyTransferActionTypes.UPDATE, error));
        } else {
            yield put(intlMoneyTransferActions.apiResponseValidationErrors(IntlMoneyTransferActionTypes.UPDATE, error));
        }
    }
}

function* cancel({ payload: {hashedId} }: IntlMoneyTransferData): SagaIterator {
    try {
        const param = {

            query:`mutation CancelIntlMoneyTransfer(
                $hashedId: String!
            ) {
                cancelIntlMoneyTransfer(
                    hashedId: $hashedId
                )
            }`,

            variables: {
                hashedId
            },
            operationName: "CancelIntlMoneyTransfer"
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, IntlMoneyTransferErrors.RESPONSE_200);

        const rIntlMoneyTransfer = response.data;

        checkServerError(rIntlMoneyTransfer);

        pluckResponse(rIntlMoneyTransfer, "cancelIntlMoneyTransfer");

        yield put(intlMoneyTransferActions.apiResponseSuccess(IntlMoneyTransferActionTypes.CANCEL, hashedId));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(intlMoneyTransferActions.apiResponseError(IntlMoneyTransferActionTypes.CANCEL, error));
        } else {
            yield put(intlMoneyTransferActions.apiResponseValidationErrors(IntlMoneyTransferActionTypes.CANCEL, error));
        }
    }
}

function* review({ payload: { data } }: IntlMoneyTransferData): SagaIterator {
    try {
        const param = {
            query:`query IntlMoneyTransferReview($amount: String!, $currency: String!, $rate: String) {
                intlMoneyTransferReview(amount: $amount, currency: $currency, rate: $rate) {
                    amount
                    amount_currency
                    rate
                    rate_currency
                    fee
                    fee_currency
                    final_amount
                    final_amount_currency
                }
            }`,

            variables: { ...data },
            operationName: "IntlMoneyTransferReview"
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, IntlMoneyTransferErrors.RESPONSE_200);

        const rOPR = response.data;

        checkServerError(rOPR);

        const intlMoneyTransferReview = pluckResponse(rOPR, "intlMoneyTransferReview");

        yield put(intlMoneyTransferActions.apiResponseSuccess(IntlMoneyTransferActionTypes.REVIEW, intlMoneyTransferReview));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(intlMoneyTransferActions.apiResponseError(IntlMoneyTransferActionTypes.REVIEW, error));
        } else {
            yield put(intlMoneyTransferActions.apiResponseValidationErrors(IntlMoneyTransferActionTypes.REVIEW, error));
        }
    }
}


function* updateMTSetting({ payload: { data, trackId } }: IntlMoneyTransferData): SagaIterator {

    try {

        const paymentMethods = data.paymentMethods || [];

        // Transform the array of objects into separate arrays for each field
        const paymentMethod = paymentMethods.map((item: any) => item.paymentMethod);
        const currencyCat = paymentMethods.map((item: any) => item.currencyCat);
        const intlMoneyTransferFee = paymentMethods.map((item: any) => item.intlMoneyTransferFee);


        const param = {
            query:`mutation UpdateIntlMoneyTransferSetting(
                $paymentMethod: [String!]
                $currencyCat: [String!]
                $intlMoneyTransferFee: [Float!]
                $intlMoneyTransferStatus: String!
            ) {
                updateIntlMoneyTransferSetting(
                    paymentMethod: $paymentMethod
                    currencyCat: $currencyCat
                    intlMoneyTransferFee: $intlMoneyTransferFee
                    intlMoneyTransferStatus: $intlMoneyTransferStatus
                )
            }`,

            variables: {
                paymentMethod,
                currencyCat,
                intlMoneyTransferFee,
                intlMoneyTransferStatus: data?.intlMoneyTransferStatus,
            },

            operationName: "UpdateIntlMoneyTransferSetting"
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, IntlMoneyTransferErrors.RESPONSE_200);

        const responseCR = response.data;

        checkServerError(responseCR);

        pluckResponse(responseCR, "updateIntlMoneyTransferSetting");

        yield put(intlMoneyTransferActions.apiResponseSuccess(IntlMoneyTransferActionTypes.UPDATE_SETTING, {
            result: true,
            trackId: trackId
        }));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(intlMoneyTransferActions.apiResponseError(IntlMoneyTransferActionTypes.UPDATE_SETTING, error, trackId));
        } else {
            yield put(intlMoneyTransferActions.apiResponseValidationErrors(IntlMoneyTransferActionTypes.UPDATE_SETTING, error, trackId));
        }
    }
}

function* getMTSetting(): SagaIterator {

    try {
        const param = {
            query:`query IntlMoneyTransferSetting {
                intlMoneyTransferSetting {
                    intl_money_transfer_status
                    intl_money_transfer_methods {
                        paymentMethod
                        currencyCat
                        intlMoneyTransferFee
                    }
                }
            }`,

            operationName: "IntlMoneyTransferSetting"
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, IntlMoneyTransferErrors.RESPONSE_200);

        const responseCR = response.data;

        checkServerError(responseCR);

        const intlMoneyTransferSetting = pluckResponse(responseCR, "intlMoneyTransferSetting");

        yield put(intlMoneyTransferActions.apiResponseSuccess(IntlMoneyTransferActionTypes.GET_SETTING, intlMoneyTransferSetting));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(intlMoneyTransferActions.apiResponseError(IntlMoneyTransferActionTypes.GET_SETTING, error));
        } else {
            yield put(intlMoneyTransferActions.apiResponseValidationErrors(IntlMoneyTransferActionTypes.GET_SETTING, error));
        }
    }
}

export function* watchOrder() {
    yield takeEvery(IntlMoneyTransferActionTypes.ORDER, order);
}

export function* watchInsert() {
    yield takeEvery(IntlMoneyTransferActionTypes.CREATE, create);
}

export function* watchUpdate() {
    yield takeEvery(IntlMoneyTransferActionTypes.UPDATE, update);
}

export function* watchCancel() {
    yield takeEvery(IntlMoneyTransferActionTypes.CANCEL, cancel);
}

export function* watchReview() {
    yield takeEvery(IntlMoneyTransferActionTypes.REVIEW, review);
}

export function* watchUpdateMTSetting() {
    yield takeEvery(IntlMoneyTransferActionTypes.UPDATE_SETTING, updateMTSetting);
}

export function* watchGetMTSetting() {
    yield takeEvery(IntlMoneyTransferActionTypes.GET_SETTING, getMTSetting);
}

function* intlMoneyTransferSaga() {
    yield all([
        fork(watchOrder),
        fork(watchInsert),
        fork(watchUpdate),
        fork(watchCancel),
        fork(watchReview),
        fork(watchUpdateMTSetting),
        fork(watchGetMTSetting),
    ]);
}

export default intlMoneyTransferSaga;
