import { all, fork, put, takeEvery, call, takeLatest } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import { graphql, graphqlUpload } from 'helpers';
import {OfflineDepositActionTypes, OfflineDepositErrors} from './constants';
import {
    checkResponseError,
    checkServerError,
    pluckResponse,
} from "../../helpers/functions";
import {offlineDepositActions} from "./actions";
import { END, eventChannel } from 'redux-saga';

type DepositData = {
    payload: {
        id: string;
        name: string;
        permissions: string[];

        queryParams: {
            limit: string;
            page: string;
            orderBy: string;
            sort: string;

            depositName?: string;
        };

        data: any;
        depositId: string;
        status: string;
    };
    type: string;
};

function* getDeposits({ payload: { queryParams } }: DepositData): SagaIterator {

    try {
        const param = {
            query:`query OfflineDeposits($page: Int!, $limit: Int, $orderBy: String!, $sort: String!, $accountId: String) {
                offlineDeposits(page: $page, limit: $limit, orderBy: $orderBy, sort: $sort, accountId: $accountId) {
                    data {
                        hashedId
                        status
                        user {
                            username
                            display_name
                        }
                        created_at,
                        amount
                        fee
                        final_amount
                        
                        offlineTransaction {
                            transaction_id
                        }
                        
                        offlineDeposit {
                            method
                            bankAccount {
                                bank_name
                                number
                            }
                            dst_bank
                            dst_account_number
                        }
                    }
                    total
                    per_page
                    from
                    to
                    current_page
                    last_page
                    has_more_pages
                }
            }`,

            variables: {
                ...queryParams
            }
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, OfflineDepositErrors.RESPONSE_200);

        const rDeposits = response.data;

        checkServerError(rDeposits);

        const deposits = pluckResponse(rDeposits, "offlineDeposits");

        const result = {
            deposits,
            queryParams
        }

        yield put(offlineDepositActions.apiResponseSuccess(OfflineDepositActionTypes.GET_DEPOSITS, result));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(offlineDepositActions.apiResponseError(OfflineDepositActionTypes.GET_DEPOSITS, error));
        } else {
            yield put(offlineDepositActions.apiResponseValidationErrors(OfflineDepositActionTypes.GET_DEPOSITS, error));
        }
    }
}

function* getDeposit({ payload: { id } }: DepositData): SagaIterator {

    try {
        const param = {
            query:`query OfflineDeposit($id: String!) {
                offlineDeposit(id: $id) {
                    hashedId
                    status
                    user {
                        username
                        display_name
                    }
                    created_at,
                    amount
                    offlineTransaction {
                        transaction_id
                    }
                    
                    offlineDeposit {
                        method
                        bankAccount {
                            bank_name
                            number
                        }
                        dst_bank
                        dst_account_number
                        
                        receipt_url
                    }
                }
            }`,

            variables: {
                id: id
            }
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, OfflineDepositErrors.RESPONSE_200);

        const rD = response.data;

        checkServerError(rD);

        const offlineDeposit = pluckResponse(rD, "offlineDeposit");

        yield put(offlineDepositActions.apiResponseSuccess(OfflineDepositActionTypes.GET_DEPOSIT, offlineDeposit));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(offlineDepositActions.apiResponseError(OfflineDepositActionTypes.GET_DEPOSIT, error));
        } else {
            yield put(offlineDepositActions.apiResponseValidationErrors(OfflineDepositActionTypes.GET_DEPOSIT, error));
        }
    }
}

function createUploader(file: File, query:any) {

    let emit: any;

    const chan = eventChannel((emitter) => {
        emit = emitter;
        return () => {};
    });

    const uploadProgressCb = ({ total, loaded }: any) => {
        const percentage = Math.round((loaded * 100) / total);
        emit(percentage);
        if (percentage === 100) emit(END);
    };

    // make form object
    let data = new FormData();
    data.set('operations', JSON.stringify(query));

    data.set('operationName', query.operationName);
    data.set('map', JSON.stringify({"file":["variables.file"]}));
    data.append('file', file);

    const uploadPromise = graphqlUpload(data, 'auth', uploadProgressCb);
    return [uploadPromise, chan];
}

function* uploadNcBackProgressWatcher(chan:any, fileId: string): SagaIterator {
    // while (true) {
    //     // const progress = yield take(chan);
    //     // yield put(nCBackActions.uploadNationalCardBackProgress(progress, fileId));
    // }
}

function* insertDeposit({ payload: { data } }: any): SagaIterator {

    try {
        const query = {
            query: `
              mutation CreateOfflineDeposit(
                  $method: String!,
                  $iban: String,
                  $dstBank: String!,
                  $dstBankAccount: String,
                  $amount: Float!,
                  $file: Upload!
              ) {
                  createOfflineDeposit(
                      method: $method
                      iban: $iban
                      dstBank: $dstBank
                      dstBankAccount: $dstBankAccount
                      amount: $amount
                      file: $file
                  )
              }
            `,
            variables: {
                ...data,
            },
            operationName: "CreateOfflineDeposit"
        };

        const [uploadPromise, chan] = yield call(createUploader, data.file, query);
        yield fork(uploadNcBackProgressWatcher, chan, data.file.fileId);

        const response = yield call(() => uploadPromise);

        checkResponseError(response, OfflineDepositErrors.RESPONSE_200);

        const rCD = response.data;
        checkServerError(rCD);

        const createOfflineDeposit = pluckResponse(rCD, "createOfflineDeposit");

        yield put(
            offlineDepositActions.apiResponseSuccess(
                OfflineDepositActionTypes.INSERT_DEPOSIT,
                createOfflineDeposit
            )
        );
    } catch (error: any) {
        if(typeof error === "string") {
            yield put(offlineDepositActions.apiResponseError(OfflineDepositActionTypes.INSERT_DEPOSIT, error));
        } else {
            yield put(offlineDepositActions.apiResponseValidationErrors(OfflineDepositActionTypes.INSERT_DEPOSIT, error));
        }
    }
}


function* searchDeposit({ payload: { queryParams } }: DepositData): SagaIterator {
    try {
        const param = {
            query:`query OfflineDeposits(
                $page: Int!, 
                $limit: Int, 
                $orderBy: String!, 
                $sort: String!, 
                $minAmount: String,
                $maxAmount: String,
                $createdFrom: String,
                $createdTo: String,
                $depositId: String,
                $transactionId: String,
                $accountId: String,
                $transactionNames: [String],
                $status: String,
            ) {
                offlineDeposits(
                    page: $page, 
                    limit: $limit, 
                    orderBy: $orderBy, 
                    sort: $sort, 
                    minAmount: $minAmount,
                    maxAmount: $maxAmount,
                    createdFrom: $createdFrom,
                    createdTo: $createdTo,
                    depositId: $depositId,
                    transactionId: $transactionId,
                    accountId: $accountId,
                    transactionNames: $transactionNames,
                    status: $status,
                ) {
                    data {
                        hashedId
                        status
                        user {
                            username
                            display_name
                        }
                        created_at,
                        amount
                        fee
                        final_amount
                        
                        offlineTransaction {
                            transaction_id
                        }
                        
                        offlineDeposit {
                            method
                            bankAccount {
                                bank_name
                                number
                            }
                            dst_bank
                            dst_account_number
                        }
                    }
                    total
                    per_page
                    from
                    to
                    current_page
                    last_page
                    has_more_pages
                }
            }`,

            variables: {
                ...queryParams
            }
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, OfflineDepositErrors.RESPONSE_200);

        const rDeposits = response.data;

        checkServerError(rDeposits);

        const offlineDeposits = pluckResponse(rDeposits, "offlineDeposits");

        const result = {
            deposits: offlineDeposits,
            queryParams
        }

        yield put(offlineDepositActions.apiResponseSuccess(OfflineDepositActionTypes.SEARCH_DEPOSIT, result));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(offlineDepositActions.apiResponseError(OfflineDepositActionTypes.SEARCH_DEPOSIT, error));
        } else {
            yield put(offlineDepositActions.apiResponseValidationErrors(OfflineDepositActionTypes.SEARCH_DEPOSIT, error));
        }
    }
}

function* exportTransactions({ payload: { queryParams } }: DepositData): SagaIterator {
    try {
        const param = {
            query:`query ExportOfflineDeposits(
                $page: Int!, 
                $limit: Int, 
                $orderBy: String!, 
                $sort: String!, 
                $wallet: String, 
                $minAmount: String,
                $maxAmount: String,
                $createdFrom: String,
                $createdTo: String,
                $transactionId: String,
                $threadId: String,
                $accountId: String,
                $transactionNames: [String],
                $transactionType: String
            ) {
                exportOfflineDeposits(
                    page: $page, 
                    limit: $limit, 
                    orderBy: $orderBy, 
                    sort: $sort, 
                    wallet: $wallet,
                    minAmount: $minAmount,
                    maxAmount: $maxAmount,
                    createdFrom: $createdFrom,
                    createdTo: $createdTo,
                    transactionId: $transactionId,
                    threadId: $threadId,
                    accountId: $accountId,
                    transactionNames: $transactionNames,
                    transactionType: $transactionType
                )
            }`,

            variables: {
                ...queryParams
            }
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, OfflineDepositErrors.RESPONSE_200);

        const rTransactions = response.data;

        checkServerError(rTransactions);

        const transactions = pluckResponse(rTransactions, "exportOfflineDeposits");

        yield put(offlineDepositActions.apiResponseSuccess(OfflineDepositActionTypes.EXPORT, transactions));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(offlineDepositActions.apiResponseError(OfflineDepositActionTypes.EXPORT, error));
        } else {
            yield put(offlineDepositActions.apiResponseValidationErrors(OfflineDepositActionTypes.EXPORT, error));
        }
    }
}

function* getOptions({ payload: { data } }: DepositData): SagaIterator {

    try {

        const param = {
            query:`query OfflineDepositOptions($options: [String!]) {
                offlineDepositOptions(options: $options) {
                    offline_deposit_status
                    offline_deposit_bank_accounts {
                        label
                        bankName
                        accountNumber
                        cardNumber
                        iban
                    }
                }
            }`,

            variables: {
                options: data
            },

            operationName: "OfflineDepositOptions"
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, OfflineDepositErrors.RESPONSE_200);

        const responseCR = response.data;

        checkServerError(responseCR);

        const premiumSubscriptionSetting = pluckResponse(responseCR, "offlineDepositOptions");

        yield put(offlineDepositActions.apiResponseSuccess(OfflineDepositActionTypes.GET_OPTIONS, premiumSubscriptionSetting));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(offlineDepositActions.apiResponseError(OfflineDepositActionTypes.GET_OPTIONS, error));
        } else {
            yield put(offlineDepositActions.apiResponseValidationErrors(OfflineDepositActionTypes.GET_OPTIONS, error));
        }
    }
}

function* approve({ payload: { data } }: DepositData): SagaIterator {
    try {
        const param = {
            query:`mutation ApproveOfflineDeposit(
                $depositId: String!,
                $amount: String!,
                $status: String!,
            ) {
                approveOfflineDeposit(
                    depositId: $depositId,
                    amount: $amount,
                    status: $status,
                )
            }`,
            variables: {
                ...data
            }
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, OfflineDepositErrors.RESPONSE_200);

        const rUpdateDeposit = response.data;

        checkServerError(rUpdateDeposit);

        const updateDeposit = pluckResponse(rUpdateDeposit, "approveOfflineDeposit");

        yield put(offlineDepositActions.apiResponseSuccess(OfflineDepositActionTypes.APPROVE, updateDeposit));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(offlineDepositActions.apiResponseError(OfflineDepositActionTypes.APPROVE, error));
        } else {
            yield put(offlineDepositActions.apiResponseValidationErrors(OfflineDepositActionTypes.APPROVE, error));
        }
    }
}


export function* watchGetDeposits() {
    yield takeEvery(OfflineDepositActionTypes.GET_DEPOSITS, getDeposits);
}

export function* watchGetDeposit() {
    yield takeEvery(OfflineDepositActionTypes.GET_DEPOSIT, getDeposit);
}

export function* watchInsertDeposit() {
    yield takeEvery(OfflineDepositActionTypes.INSERT_DEPOSIT, insertDeposit);
}

export function* watchSearchDeposit() {
    yield takeEvery(OfflineDepositActionTypes.SEARCH_DEPOSIT, searchDeposit);
}

export function* watchExportTransactions() {
    yield takeEvery(OfflineDepositActionTypes.EXPORT, exportTransactions);
}

function* watchGetOptions() {
    yield takeLatest(OfflineDepositActionTypes.GET_OPTIONS, getOptions);
}

function* watchApproveOfflineDeposit() {
    yield takeLatest(OfflineDepositActionTypes.APPROVE, approve);
}

function* offlineDepositsSaga() {
    yield all([
        fork(watchGetDeposits),
        fork(watchGetDeposit),
        fork(watchInsertDeposit),
        fork(watchSearchDeposit),
        fork(watchExportTransactions),
        fork(watchGetOptions),
        fork(watchApproveOfflineDeposit),
    ]);
}

export default offlineDepositsSaga;
