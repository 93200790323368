import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {OfflineDepositSettingActionTypes} from "./constants";
import {offlineDepositSettingActions} from "./actions";
import {graphql} from "../../helpers";
import { SagaIterator } from "@redux-saga/core";
import { checkResponseError, checkServerError, pluckResponse } from "../../helpers/functions";
import { OfflineDepositErrors } from '../offlineDeposit/constants';

type OfflineDepositSettingData = {
    payload: {
        id: string;

        limit: string;
        page: string;

        data: any,
        trackId: string
    };
    type: string;
};

function* updateSetting({ payload: { data, trackId } }: any): SagaIterator {
    try {
        const offlineDepositStatus = data.offline_deposit_status;
        const bankAccounts = data.bankAccounts || [];
        const bankName = bankAccounts.map((account: any) => account.bankName);
        const iban = bankAccounts.map((account: any) => account.iban);
        const accountNumber = bankAccounts.map((account: any) => account.accountNumber);
        const cardNumber = bankAccounts.map((account: any) => account.cardNumber);

        const param = {
            query: `mutation UpdateOfflineDepositSetting(
                $bankName: [String!],
                $iban: [String!],
                $accountNumber: [String!],
                $cardNumber: [String],
                $offline_deposit_status: String!
            ) {
                updateOfflineDepositSetting(
                    bankName: $bankName,
                    iban: $iban,
                    accountNumber: $accountNumber,
                    cardNumber: $cardNumber,
                    offline_deposit_status: $offline_deposit_status
                )
            }`,
            variables: { bankName, iban, accountNumber, cardNumber, offline_deposit_status: offlineDepositStatus },
            operationName: "UpdateOfflineDepositSetting"
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, OfflineDepositErrors.RESPONSE_200);

        const responseCR = response.data;

        checkServerError(responseCR);

        pluckResponse(responseCR, "updateOfflineDepositSetting");

        yield put(offlineDepositSettingActions.apiResponseSuccess(
            OfflineDepositSettingActionTypes.UPDATE_SETTING,
            { result: true, trackId }
        ));
    } catch (error: any) {
        if (typeof error === "string") {
            yield put(offlineDepositSettingActions.apiResponseError(
                OfflineDepositSettingActionTypes.UPDATE_SETTING,
                error,
                trackId
            ));
        } else {
            yield put(offlineDepositSettingActions.apiResponseValidationErrors(
                OfflineDepositSettingActionTypes.UPDATE_SETTING,
                error,
                trackId
            ));
        }
    }
}

function* getOptions({ payload: { data } }: OfflineDepositSettingData): SagaIterator {

    try {

        const param = {
            query:`query OfflineDepositOptions($options: [String!]) {
                offlineDepositOptions(options: $options) {
                    offline_deposit_status
                    offline_deposit_bank_accounts {
                        label
                        bankName
                        accountNumber
                        cardNumber
                        iban
                    }
                }
            }`,

            variables: {
                options: data
            },

            operationName: "OfflineDepositOptions"
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, OfflineDepositErrors.RESPONSE_200);

        const responseCR = response.data;

        checkServerError(responseCR);

        const premiumSubscriptionSetting = pluckResponse(responseCR, "offlineDepositOptions");

        yield put(offlineDepositSettingActions.apiResponseSuccess(OfflineDepositSettingActionTypes.GET_OPTIONS, premiumSubscriptionSetting));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(offlineDepositSettingActions.apiResponseError(OfflineDepositSettingActionTypes.GET_OPTIONS, error));
        } else {
            yield put(offlineDepositSettingActions.apiResponseValidationErrors(OfflineDepositSettingActionTypes.GET_OPTIONS, error));
        }
    }
}

export function* watchUpdateSetting() {
    yield takeEvery(OfflineDepositSettingActionTypes.UPDATE_SETTING, updateSetting);
}

export function* watchGetOptions() {
    yield takeEvery(OfflineDepositSettingActionTypes.GET_OPTIONS, getOptions);
}

function* offlineDepositSettingSaga() {
    yield all([
        fork(watchUpdateSetting),
        fork(watchGetOptions),
    ]);
}

export default offlineDepositSettingSaga;
